import React, { useEffect, useState, useCallback, useRef } from "react";
import { Card, Form, Input, Select, DatePicker } from "antd";
import MyButton from "components/ui/Button/MyButton";
import { disabledFutureDate, disabledPastDate } from "components/common/validation";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployeeType } from "hooks/api/masterApi/employeeTypeApi";
import { getAllPayrollPeriod } from "hooks/api/configurationApi/payrollPeriodApi";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { PAYROLL_RUN_GET } from "constants";
import ToastMsg from "components/common/ToastMsg";
import { CalendarOutlined } from "@ant-design/icons";
import {checkUserPermissionFunc} from "components/common/CommonFuntion";

export const PayrollProcess = () => {
  const authtoken = sessionStorage.getItem("token");
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [departmentList, setDepartmentList] = useState([]);
  const [eType, setEType] = useState([]);
  const [payrollList, setPayrollList] = useState([]);
  const [formData, setFormData] = useState({
    payroll_date: moment().format("DD-MM-YYYY HH:mm:ss"),
    payroll_month_year: "",
    from_date: "",
    to_date: "",
    employee_type: [],
    department: [],
    payroll_period: "",
    total_days: ""
  });
  const [dropdownVisibility, setDropdownVisibility] = useState({
    department: false,
    employee_type: false,
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [omittedValues, setOmittedValues] = useState([]);
  const hideCursorRef = useRef(null);

  const getIdList = (list, mapList) => {
    if (list?.length > 0 && mapList?.length > 0) {
      if (list.includes('all')) {
        return mapList.map(item => item.id);
      } else {
        return list;
      }
    } else {
      return [];
    }
  };

  const userPermission = checkUserPermissionFunc("payroll", "Run Payroll", "SubMenu");

  const handleSubmit = useCallback(() => {
    try {
      setLoading(true)
      let department_list = getIdList(formData?.department, departmentList);
      let typeList = getIdList(formData?.employee_type, eType);
      const parsedDate = formData?.payroll_month_year ? moment(formData?.payroll_month_year, "MMM YYYY") : null;
      let payrollMonth = null, payrollYear = null;
      if (parsedDate) {
        payrollMonth = parsedDate.month() + 1;
        payrollYear = parsedDate.year();
      }

      let updatedFormData = {
        "payroll_date": formData.payroll_date ? moment(formData.payroll_date, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : null,
        "payroll_month": payrollMonth,
        "payroll_year": payrollYear,
        "department": department_list,
        "employee_type": typeList,
        "payroll_period": formData.payroll_period,
        "from_date": formData.from_date ? moment(formData.from_date, 'DD-MM-YYYY').format('YYYY-MM-DD') : null,
        "to_date": formData.to_date ? moment(formData.to_date, 'DD-MM-YYYY').format('YYYY-MM-DD') : null,
        "total_days": formData.total_days ? formData.total_days : ""
      };

      fetch(PAYROLL_RUN_GET, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            navigate('/payroll/runpayroll/process', { state: { PaySlipId: data.data } });
            setLoading(false);
            handleClear();
            ToastMsg("success", data.message);
          }
          else if (data.status === "fail") {
            ToastMsg("error", data.message);
            setLoading(false);
          }
        })
        .catch(error => {
          error.json().then(data => {
            setLoading(false);
            ToastMsg("error", data);
          });
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData]);

  const navigate = useNavigate();

  const calculateFromAndToDate = (payroll_period, payroll_month_year) => {
    let newFromDate = null, newToDate = null, newTotalDays = 0;
 
    if (payroll_period && payroll_month_year) {
      const selectedPeriod = payrollList.find((item) => item.id === payroll_period) || {};
      if (Object.keys(selectedPeriod).length === 0) return;
      const { start_day, end_day, month: selectedMonths, period } = selectedPeriod;
      const parsedDate = moment(payroll_month_year, "MMM YYYY");
      if (!parsedDate.isValid()) return;
  
      // current month and current year represents the selected Payroll Month & Year;
      const currentMonth = parsedDate.month() + 1;
      const currentYear = parsedDate.year();
  
      if (period === "month_wise") {
        let startDay = start_day;
        let endDay = end_day;
  
        const daysInCurrentMonth = moment(`${currentYear}-${currentMonth}`, 'YYYY-MM').daysInMonth();
  
        if (startDay > daysInCurrentMonth) {
          startDay = daysInCurrentMonth;
        }
        if (endDay > daysInCurrentMonth) {
          endDay = daysInCurrentMonth;
        }
  
        newFromDate = moment(`${currentYear}-${currentMonth}-${startDay}`, 'YYYY-MM-DD').format('DD-MM-YYYY');
        newToDate = moment(`${currentYear}-${currentMonth}-${endDay}`, 'YYYY-MM-DD').format('DD-MM-YYYY');
        newTotalDays = daysInCurrentMonth;
      } else if (period === "customize") {
        const previousMonth = parsedDate.clone().subtract(1, 'month');
        const payrollMonth = previousMonth.month() + 1;
        const payrollYear = previousMonth.year();
        const daysInSelectedMonth = moment(`${payrollYear}-${payrollMonth}`, 'YYYY-MM').daysInMonth();

        if (selectedMonths.includes(currentMonth) || selectedMonths.includes(0)) {
          const adjustedStartDay = Math.min(start_day, daysInSelectedMonth);
          const adjustedEndDay = Math.min(end_day, daysInSelectedMonth);
          let startDate = moment(`${payrollYear}-${payrollMonth}-${adjustedStartDay}`, 'YYYY-MM-DD');
          let endDate = moment(`${payrollYear}-${payrollMonth}-${adjustedEndDay}`, 'YYYY-MM-DD');

          if (endDate.isBefore(startDate)) {
            endDate = endDate.add(1, 'month');
          }
          newFromDate = startDate.format('DD-MM-YYYY');
          newToDate = endDate.format('DD-MM-YYYY');
          newTotalDays = endDate.diff(startDate, 'days') + 1;
        } else {
          ToastMsg("error", 'To run the payroll, please ensure that the payroll period has been configured');
        }
      }
  
      setFormData((prevState) => ({
        ...prevState,
        from_date: newFromDate,
        to_date: newToDate,
        total_days: newTotalDays
      }));
  
      form.setFieldsValue({
        from_date: newFromDate,
        to_date: newToDate,
        total_days: newTotalDays
      });
    }
  };

  const getDepartmentList = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setDepartmentList([...apiData.data])
      } else {
        setDepartmentList([])
      }
    } catch (error) {
      setDepartmentList([])
    }
  }

  const getEmployeeTypeList = async () => {
    setEType([])
    try {
      const apiData = await getEmployeeType(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEType(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setEType([])
      }
    } catch (error) {
      setEType([])
    }
  };

  const getPayrollPeriodList = async () => {
    setPayrollList([])
    try {
      const apiData = await getAllPayrollPeriod(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setPayrollList(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setPayrollList([])
      }
    } catch (error) {
      setPayrollList([])
    }
  };

  useState(() => {
    getDepartmentList();
    getEmployeeTypeList();
    getPayrollPeriodList();
  }, [])

  const handleChange = (name, value) => {
    switch (name) {
      case "department":
      case "employee_type":
        if (value.includes('all')) {
          setFormData((prevState) => ({
            ...prevState,
            [name]: ["all"],
          }));
          form.setFieldsValue({
            [name]: ["all"],
          });
          
        } else {
          setFormData((prevState) => ({
            ...prevState,
            [name]: value
          }));
          form.setFieldsValue({
            [name]: value,
          });
        }
        break;
      case "payroll_month_year":
        calculateFromAndToDate(formData.payroll_period, value);
        setFormData((prevState) => ({
          ...prevState,
          payroll_month_year: value
        }))
        break;
      case "payroll_period":
        calculateFromAndToDate(value, formData.payroll_month_year);
        setFormData((prevState) => ({
          ...prevState,
          payroll_period: value
        }));
        form.setFieldsValue({
          [name]: value,
        });
        break;
      default:
        setFormData((prevState) => ({
          ...prevState,
          [name]: value
        }))
        break;
    }
    setDropdownVisibility(prevState => ({
      ...prevState,
      [name]: (name === 'department' || name === 'employee_type') && value.includes('all') ? false : true
    }));
  }

  const handleToggleTooltip = (visible, values, position) => {
    setShowTooltip(visible);
    setOmittedValues(values);
    setTooltipPosition(position);
  };

  const handleMouseEnter = (event, omittedValues) => {
    const rect = event.target.getBoundingClientRect();
    handleToggleTooltip(true, omittedValues, { x: rect.left, y: rect.bottom });
  };

  const handleMouseLeave = () => {
    handleToggleTooltip(false, [], { x: 0, y: 0 });
  };

  const renderMaxTagPlaceholder = (omittedValues) => (
    <div
      onMouseEnter={(event) => handleMouseEnter(event, omittedValues)}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: 'pointer' }}
    >
      +{omittedValues.length}
    </div>
  );

  const renderSelect = (statekey, list = [], showKey = "text", valueKey = "value", isMulti = false) => {
    return (
      <>
      <Select
        showSearch
        mode={isMulti ? "multiple" : null}
        showArrow="true"
        value={formData[statekey] ? formData[statekey] : undefined}
        name={statekey}
        onChange={(value) => handleChange(statekey, value)}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        open={statekey === "department" ? dropdownVisibility.department : statekey === "employee_type" ? dropdownVisibility.employee_type : undefined}
        onDropdownVisibleChange={(open) => {
          setDropdownVisibility(prevState => ({
            ...prevState,
            [statekey]: open
          }));
        }}
        onDeselect={(value) => {
          setDropdownVisibility(prevState => ({
            ...prevState,
            [statekey]: (statekey === 'department' || statekey === 'employee_type') && value === 'all' ? true : false
          }));
        }}
        maxTagCount={2}
        maxTagTextLength={5}
        maxTagPlaceholder={(omittedValues) => renderMaxTagPlaceholder(omittedValues)}
        ref={statekey === "payroll_period" ? hideCursorRef : null}
        onSelect={() => {
          if(statekey === "payroll_period"){
            hideCursorRef.current.blur();
          }
        }} 
      >
        {isMulti && list?.length > 0 ?
          <Select.Option key={"all"} value={"all"}> All</Select.Option> : null}
        {list?.length > 0 && list.map((option) => (
          (valueKey === "id" && (option.id === formData[statekey] || option.is_active)) ? 
          <Select.Option key={option[valueKey]} value={option[valueKey]}
            disabled={(statekey === "department" && formData.department.includes('all')) ||
              (statekey === "employee_type" && formData.employee_type.includes('all'))}>
            {option[showKey]}
          </Select.Option>
          : null
        ))}
        </Select>
        {omittedValues.length > 0 && showTooltip && (
          <div className="commonTooltipOfMutliSlt" 
          style={{ position: 'fixed', top: tooltipPosition.y + 10, left: tooltipPosition.x - 55 }}>
            <ul className="commonTooltipOfMutliSltDiv">
              {omittedValues?.map(value => (
                <li key={value.key} className="commonTooltipOfMutliSltTxt">{value.label}</li>
              ))}
            </ul>
          </div>
        )}
      </>
    )
  }

  const handleClear = () => {
    setFormData((prevState) => ({
      ...prevState,
      payroll_month_year: "",
      from_date: "",
      to_date: "",
      employee_type: [],
      department: [],
      payroll_period: "",
      total_days: ""
    }));

    form.setFieldsValue({
      payroll_month_year: "",
      from_date: "",
      to_date: "",
      employee_type: [],
      department: [],
      payroll_period: "",
      total_days: ""
    })
  }

  return (
    <Card className="w-full">
      <p className='common_addTltTxt pb-2 border-b mb-5'>Payroll Process</p>
      <Form
        layout="vertical"
        name="runPayrollProcess"
        onSubmit={(e) => e.preventDefault()}
        form={form}
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <div className="grid grid-row-2 grid-cols-4 gap-6">
          <Form.Item
            name="payrollName"
            style={{ textAlign: "left" }}
            label={"Payroll Generation Date & Time"}
            rules={[
              { required: false, message: "This field is required" },
            ]}
            initialValue={formData.payroll_date}
          >
            <Input
              name="payroll_date"
              bordered={false}
              style={styles.textfield}
              disabled suffix={<CalendarOutlined />} />
          </Form.Item>
          <Form.Item
            name="payroll_month_year"
            style={{ textAlign: "left" }}
            label={"Payroll Month & Year"}
            rules={[
              { required: true, message: "This field is required" },
            ]}
            tooltip={{
              title: "This field is required",
              icon: <span style={{ color: "red" }}>*</span>,
            }}
          >
            <DatePicker
              allowClear
              inputReadOnly={true}
              style={{ width: "100%" }}
              name={"payroll_month_year"}
              showTime
              format="MMMM YYYY"
              picker="month"
              value={formData?.payroll_month_year ? formData.payroll_month_year : undefined}
              onChange={(date, dateString) => handleChange("payroll_month_year", dateString)}
              disabledDate={disabledFutureDate}
              placeholder = ""
            />  
          </Form.Item>
          <Form.Item
            style={{ textAlign: "left" }}
            name="department"
            label={"Select Department"}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
            tooltip={{
              title: "This field is required",
              icon: <span style={{ color: "red" }}>*</span>,
            }}
          >
            {renderSelect("department", departmentList, "department_name", "id", true)}
          </Form.Item>
          <Form.Item
            style={{ textAlign: "left" }}
            name="employee_type"
            label={"Select Employee Type"}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
            tooltip={{
              title: "This field is required",
              icon: <span style={{ color: "red" }}>*</span>,
            }}
          >
            {renderSelect("employee_type", eType, "employee_type_name", "id", true)}
          </Form.Item>
          <Form.Item
            style={{ textAlign: "left" }}
            name="payroll_period"
            label={"Select Payroll Period"}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
            tooltip={{
              title: "This field is required",
              icon: <span style={{ color: "red" }}>*</span>,
            }}
          >
            {renderSelect("payroll_period", payrollList, "payroll_period_name", "id")}
          </Form.Item>
          <Form.Item
            style={{ textAlign: "left" }}
            name="from_date"
            label={"Payroll From Date"}
            rules={[
              { required: false, message: "This field is required" },
            ]}
           
          >
            <Input
              name="from_date"
              bordered={false}
              style={styles.textfield}
              disabled suffix={<CalendarOutlined />} />
          </Form.Item>
          <Form.Item
            style={{ textAlign: "left" }}
            name="to_date"
            label={"Payroll To Date"}
            rules={[{ required: false, message: "This field is required" }]}
      
          >
            <Input
              name="to_date"
              bordered={false}
              style={styles.textfield}
              disabled suffix={<CalendarOutlined />} />
          </Form.Item>
          <Form.Item
            style={{ textAlign: "left" }}
            name="total_days"
            label={"Total Days"}
            rules={[{ required: false, message: "This field is required" }]}
       
          >
            <Input
              type="number"
              name="total_days"
              disabled
            />
          </Form.Item>
        </div>
        <Form.Item >
          <div className='flex justify-center items-end' style={{ marginTop: "20vw" }}>
            <MyButton htmlType="button" label="Clear" loading={loading} onClick={handleClear} outlined = {true} paddingX={"0 0.7vw"} marginRight={"0.625vw"} />
            <MyButton htmlType="submit" label={"Next"} disabled = {!userPermission?.edit || loading} bgColor={!userPermission?.edit ? "#cbcbcb" : null} loading={loading} paddingX={"0 1.2vw"} />
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
};

const styles = {
  textfield: {
    borderBottom: "1px solid",
    borderRadius: 0,
    outline: "none",
  }
}

export default PayrollProcess;
