import React, { useState, useEffect, useRef } from 'react';
import { Input, Card, Form } from 'antd'
import {
  handleOnKeyDecimal, handleOnKeyNumeric,
  numberValidation, containsBasic,
  containsProvidentFund,
  containsESI,
  containsDearnessAllowance,
  containsTaxTerms,
  deepEqual,
  containsHRA
} from 'components/common/validation';
import { getSalaryTemplateList } from "hooks/api/configurationApi/payrollPeriodApi";
import { groupBy } from "components/common/CommonFuntion";
import { CommonGroupSelect } from "components/common/CommonComponent";
import { default_total_earnings, default_total_deduction } from "components/common/enum";

const SalaryCalculator = () => {
  const [formData, setFormData] = useState({
    annual_ctc: 0,
    monthly_ctc: 0,
    gross_monthly_amount: 0,
    gross_annual_amount: 0,
    earnings: [],
    deductions: [],
    total_earning: { ...default_total_earnings },
    total_deduction: { ...default_total_deduction },
    net_salary: 0,
    gross_salary: 0,
    total_deductions_percentage: 0,
    total_deductions_monthly_amount: 0,
    total_deductions_annual_amount: 0,
    net_salary_monthly: 0,
  });

  const [dropdownList, setDropDownList] = useState({
    actualTemplateData: [],
    salaryTemplateList: {}
  });
  const [salary_template_id, setSalary_template_id] = useState(undefined);
  const [slctedTemplate, setSlctedTemplate] = useState({});
  const [isInputDisable, setIsInputDisable] = useState(true);
  const [isEsicDisable, setIsEsicDisable] = useState(true);

  const [form] = Form.useForm();
  const formRef = useRef(null);
  const mctRef = useRef(null);
  const actRef = useRef(null);
  const prevDataRef = useRef(JSON.parse(JSON.stringify(formData)));

  const customConfig = JSON.parse(sessionStorage.getItem("customConfig"));
  // const salary_calculation = sessionStorage.getItem("salary_calculation") // "ctc" or "gross";
  const salary_calculation = "gross";

  const focusMCTC = () => {
    if (mctRef.current) {
      // Delay the focus using setTimeout to ensure it happens after the resetFields operation.
      setTimeout(() => {
        mctRef.current.focus();
      }, 50);
    }
  };

  const focusACTC = () => {
    if (actRef.current) {
      // Delay the focus using setTimeout to ensure it happens after the resetFields operation.
      setTimeout(() => {
        actRef.current.focus();
      }, 50);
    }
  };

  const getSalaryTemplate = async () => {
    try {
      const apiData = await getSalaryTemplateList(`drop_down=True`);
      if (apiData && apiData?.status === "success" && apiData?.data) {
        let groupedData = groupBy([...apiData?.data], (data) => {
          const departmentName = data?.department?.department_name ?? 'N/A';
          const designationName = data?.designation?.designation_name ?? 'N/A';
          return `${departmentName} - ${designationName}`;
        });
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          actualTemplateData: [...apiData?.data],
          salaryTemplateList: Object.keys(groupedData)?.length > 0 ? groupedData : {}
        }))
      } else {
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          actualTemplateData: [],
          salaryTemplateList: {}
        }))
      }
    } catch (error) {
      setDropDownList((prevFilterData) => ({
        ...prevFilterData,
        actualTemplateData: [],
        salaryTemplateList: {}
      }))
    }
  }

  useEffect(() => {
    getSalaryTemplate();
  }, []);

  useEffect(() => {
    if (!deepEqual(formData, prevDataRef.current)) {
      form.setFieldsValue(JSON.parse(JSON.stringify(formData)));
      prevDataRef.current = JSON.parse(JSON.stringify(formData));
    }
  }, [formData, form]);

  const updateStateAndForm = (data) => {
    if (data) {
      let templateObj = {
        earnings: data?.earning_components?.map(({ id, ...rest }) => ({
          ...rest,
          percentage: parseFloat(rest?.percentage || 0).toString()
        })) || [],
        deductions: data?.deduction_components?.map(({ id, ...rest }) => ({
          ...rest,
          employee_share_percentage: parseFloat(rest?.employee_share_percentage || 0).toString(),
          employer_share_percentage: parseFloat(rest?.employer_share_percentage || 0).toString()
        })) || [],
        total_earning: data?.total_earning || default_total_earnings,
        total_deduction: data?.total_deduction || default_total_deduction,
        monthly_ctc: data?.monthly_ctc || 0,
        annual_ctc: data?.annual_ctc || 0,
        net_salary: data?.net_salary || 0,
        net_salary_monthly: data?.net_salary_monthly || 0,
        gross_monthly_amount: data?.total_earning?.monthly_amount || 0,
        gross_annual_amount: data?.total_earning?.annual_amount || 0,
      }
      setFormData(prevState => ({ ...prevState, ...templateObj }));
    }
  };

  useEffect(() => {
    if (salary_template_id) {
      const selectedTemplate = dropdownList?.actualTemplateData?.find(template => template?.id === salary_template_id);
      if (selectedTemplate) {
        updateStateAndForm(selectedTemplate);
        setSlctedTemplate(selectedTemplate);
      }
    }
  }, [salary_template_id, dropdownList?.actualTemplateData]);

  const calculatePercentage = (values, referenceAmount) => {
    return (values === 0 || referenceAmount === 0) ? 0 : numberValidation((values / (referenceAmount / 12)) * 100);
  };

  const calculateAnnualAmount = (values, otherMonthlyAmount) => {
    return numberValidation((values + parseFloat(otherMonthlyAmount || 0)) * 12);
  };

  const getSalValue = (salaryDeductionType, prevState, annual_ctc, basicAnnualAmount) => {
    switch (salaryDeductionType) {
      case "gross":
        return numberValidation(prevState?.total_earning?.annual_amount || 0);
      // case "ctc":
      //   return numberValidation(annual_ctc);
      case "net_salary":
        return numberValidation((prevState?.net_salary_monthly || 0) * 12);
      // case "basic":
      //   return numberValidation(basicAnnualAmount);
      default:
        return 0;
    }
  };

  const calculateAmountFromPercentage = (percentage, referenceAmount) => {
    return (percentage === 0 || referenceAmount === 0) ? 0 : numberValidation((referenceAmount * percentage) / 100);
  };

  const sumAnnualAmountsExcludingHRA = (components) => {
    return components?.reduce((sum, component) => {
      if (!containsHRA(component?.name)) {
        return numberValidation(+(sum || 0) + +(component?.annual_amount || 0));
      }
      return sum;
    }, 0);
  };

  const getReferenceAmount = (item, annual_ctc, basicAnnualAmount, gross_annual_amount) => {
    const isBasic = containsBasic(item?.name);
    const isHra = containsHRA(item?.name);
    return salary_calculation === "ctc" ?
      (isBasic ? annual_ctc : basicAnnualAmount) :
      (isHra ? basicAnnualAmount : (gross_annual_amount || 0));
  }

  const calculateTotalAnnualExcludingLast = (earnings, annual_ctc, basicAnnualAmount, gross_annual_amount) => {
    return earnings?.slice(0, -1)?.reduce((total, item) => {
      const percentage = parseFloat(item?.percentage || 0);
      const referenceAmount = getReferenceAmount(item, annual_ctc, basicAnnualAmount, gross_annual_amount);
      const calcValue = calculateAmountFromPercentage(percentage, referenceAmount);
      return numberValidation(total + calcValue);
    }, 0);
  };

  const calculateTotals = (stateKey, updatedComponents, totalObj) => {
    const addValues = (total, key, value) => total[key] = numberValidation((numberValidation(total[key]) || 0) + numberValidation(value || 0));

    return updatedComponents?.reduce((totals, component) => {
      if (stateKey === "earnings") {
        addValues(totals, 'percentage', component?.percentage);
        addValues(totals, 'monthly_amount', component?.monthly_amount);
        addValues(totals, 'annual_amount', component?.annual_amount);
      } else if (stateKey === "deductions") {
        addValues(totals, 'percentage', component?.employee_share_percentage);
        addValues(totals, 'employee_monthly_amount', component?.employee_share_monthly_amount);
        addValues(totals, 'employer_monthly_amount', component?.employer_share_monthly_amount);
        addValues(totals, 'annual_amount', (numberValidation(component?.employee_share_monthly_amount || 0) + numberValidation(component?.employer_share_monthly_amount || 0)) * 12);
      }
      return totals;
    }, { ...totalObj });
  }

  const calculateEntireItems = (earnings, deductions, annual_ctc, gross_annual_amount) => {
    // Calculate total annual amount excluding the last item in earnings
    const basicComponent = earnings?.find(component => containsBasic(component?.name));

    let basicAnnualAmount = 0;

    if (basicComponent) {
      basicAnnualAmount = calculateAmountFromPercentage(parseFloat(basicComponent.percentage || 0), salary_calculation === "ctc" ? annual_ctc : gross_annual_amount);
      basicComponent.annual_amount = basicAnnualAmount;
      basicComponent.monthly_amount = numberValidation(basicAnnualAmount / 12);
    }
    const totalAnnualExcludingLast = calculateTotalAnnualExcludingLast(earnings, annual_ctc, basicAnnualAmount, gross_annual_amount);

    const calculateEarnings = (item, index) => {
      let calcValue = 0;
      let percentage = parseFloat(item.percentage || 0);
      const isLastItem = index === earnings.length - 1;
      const basicComponent = earnings?.find(component => containsBasic(component?.name));
      const basicAnnualAmount = basicComponent ? basicComponent?.annual_amount : 0;

      // Use annual_ctc if the item is "basic", otherwise use the basic amount
      const referenceAmount = getReferenceAmount (item, annual_ctc, basicAnnualAmount, gross_annual_amount);

      if (isLastItem) {
        calcValue = numberValidation((+gross_annual_amount || 0) - (+totalAnnualExcludingLast || 0));
        // Calculate the percentage needed for the last item to achieve this value
        percentage = (calcValue / referenceAmount) * 100;
        // Update item with the new percentage
        item.percentage = percentage ? numberValidation(percentage || 0).toString() : parseFloat(item.percentage || 0).toString();
      } else {
        calcValue = calculateAmountFromPercentage(percentage, referenceAmount);
      }

      return {
        ...item,
        monthly_amount: numberValidation(calcValue / 12),
        annual_amount: numberValidation(calcValue),
      };
    };
    // Calculate the updated earnings
    const updatedEarnings = earnings?.map((item, index) => calculateEarnings(item, index));

    // Recalculate total monthly earnings based on updated earnings
    const totalMonthlyEarnings = salary_calculation === "ctc" ?
      numberValidation(updatedEarnings?.reduce((total, item) => total + numberValidation(item.monthly_amount || 0), 0)) :
      (numberValidation(gross_annual_amount / 12) || 0);

    const calculateDeductions = (item, index) => {
      let percentageEmployee = parseFloat(item.employee_share_percentage || 0);
      let percentageEmployer = parseFloat(item.employer_share_percentage || 0);
      let amountEmployee = 0;
      let amountEmployer = 0;
      const basicComponent = updatedEarnings?.find(component => containsBasic(component?.name));
      const dearnessComponent = updatedEarnings?.find(component => containsDearnessAllowance(component?.name));
      const basicAnnualAmount = basicComponent ? parseFloat(basicComponent?.annual_amount || 0) : 0;
      const basicMonthlyAmount = basicComponent ? parseFloat(basicComponent?.monthly_amount || 0) : 0;
      const dearnessAnnualAmount = dearnessComponent ? parseFloat(dearnessComponent?.annual_amount || 0) : 0;

      if (containsProvidentFund(item.name)) {
        // const pfBaseAmount = numberValidation(basicAnnualAmount + dearnessAnnualAmount);
        const pfBaseAmount = sumAnnualAmountsExcludingHRA(updatedEarnings);
        if (basicMonthlyAmount < customConfig?.pf_limit) {
          amountEmployee = calculateAmountFromPercentage(percentageEmployee, pfBaseAmount);
          amountEmployer = calculateAmountFromPercentage(percentageEmployer, pfBaseAmount);
        } else {
          let cap_amount = numberValidation((customConfig?.cap_amount || 0) * 12);
          amountEmployee = numberValidation(cap_amount);
          amountEmployer = numberValidation(cap_amount);
          let capPercentage = calculatePercentage(numberValidation(customConfig?.cap_amount || 0), pfBaseAmount) || 0;
          item.employee_share_percentage = (capPercentage);
          item.employer_share_percentage = (capPercentage);
        }
      } else if (containsESI(item.name)) {
        const shouldIncludeESIC = salary_calculation === "ctc" ? totalMonthlyEarnings : numberValidation(gross_annual_amount / 12) <= 21000;
        const totalAnnualEarnings = salary_calculation === "ctc" ? numberValidation(totalMonthlyEarnings * 12) : (gross_annual_amount || 0);

        if (slctedTemplate && Array.isArray(slctedTemplate.deduction_components)) {
          percentageEmployee = numberValidation(+percentageEmployee === 0 ?
            +(slctedTemplate?.deduction_components[index]?.employee_share_percentage)
            : percentageEmployee);
          percentageEmployer = numberValidation(+percentageEmployer === 0 ?
            +(slctedTemplate?.deduction_components[index]?.employer_share_percentage)
            : percentageEmployer);
        }

        amountEmployee = shouldIncludeESIC
          ? calculateAmountFromPercentage(percentageEmployee, totalAnnualEarnings)
          : 0;
        amountEmployer = shouldIncludeESIC
          ? calculateAmountFromPercentage(percentageEmployer, totalAnnualEarnings)
          : 0;
        item.employee_share_percentage = shouldIncludeESIC ? percentageEmployee : 0;
        item.employer_share_percentage = shouldIncludeESIC ? percentageEmployer : 0;
        setIsEsicDisable(shouldIncludeESIC ? false : true);
      } else if (containsTaxTerms(item.name)) {
        amountEmployee = calculateAmountFromPercentage(percentageEmployee, basicAnnualAmount);
        amountEmployer = calculateAmountFromPercentage(percentageEmployer, basicAnnualAmount);
      } else {
        const salValue = getSalValue(customConfig?.salary_deduction, updatedEarnings, annual_ctc);
        amountEmployee = calculateAmountFromPercentage(percentageEmployee, salValue);
        amountEmployer = calculateAmountFromPercentage(percentageEmployer, salValue);
      }

      return {
        ...item,
        employee_share_percentage: numberValidation(item.employee_share_percentage).toString(),
        employer_share_percentage: numberValidation(item.employer_share_percentage).toString(),
        employee_share_monthly_amount: amountEmployee === 0 ? 0 : numberValidation(amountEmployee / 12),
        employer_share_monthly_amount: amountEmployer === 0 ? 0 : numberValidation(amountEmployer / 12),
        annual_amount: numberValidation(amountEmployee + amountEmployer),
      };
    };

    // Calculate the updated deductions
    const updatedDeductions = deductions?.map((item, index) => calculateDeductions(item, index));

    // Calculate total earnings and deductions
    let totalEarnings = calculateTotals("earnings", [...updatedEarnings], { ...default_total_earnings });
    const totalDeductions = calculateTotals("deductions", [...updatedDeductions], { ...default_total_deduction });

    // Calculate net monthly salary
    const netSalaryMonthly = numberValidation((annual_ctc - totalDeductions?.annual_amount) / 12);
    // calculate gross salary component;
    if (salary_calculation === "ctc") {
      let grossSalaryAnnual = numberValidation(annual_ctc - ((totalDeductions?.employer_monthly_amount || 0) * 12));
      totalEarnings = {
        percentage: numberValidation(totalEarnings?.percentage || 0),
        monthly_amount: numberValidation(grossSalaryAnnual / 12),
        annual_amount: numberValidation(grossSalaryAnnual),
      }
    }

    return {
      earnings: updatedEarnings,
      deductions: updatedDeductions,
      total_earning: totalEarnings,
      total_deduction: totalDeductions,
      net_salary_monthly: numberValidation(netSalaryMonthly),
      net_salary: numberValidation(netSalaryMonthly * 12),
    }
  }

  useEffect(() => {
    setFormData((prevState) => {
        const { earnings, deductions, total_earning, total_deduction, net_salary_monthly } = calculateEntireItems(
            prevState?.earnings,
            prevState?.deductions,
            prevState?.annual_ctc,
            prevState?.gross_annual_amount
        );
        let updatedEarnings = [...earnings];
        let updatedDeductions = [...deductions];

        // update the percentage values back to the template value
        if ((prevState?.annual_ctc === 0 && prevState?.gross_annual_amount === 0) && slctedTemplate) {
            updatedEarnings = slctedTemplate?.earning_components?.map(({ id, ...rest }) => ({
                ...rest,
                percentage: parseFloat(rest?.percentage || 0).toString()
            })) || [];
            updatedDeductions = slctedTemplate?.deduction_components?.map(({ id, ...rest }) => ({
                ...rest,
                employee_share_percentage: parseFloat(rest?.employee_share_percentage || 0).toString(),
                employer_share_percentage: parseFloat(rest?.employer_share_percentage || 0).toString()
            })) || [];
            setIsEsicDisable(false);
            setIsInputDisable(false);
        }

        // const annualCtc = numberValidation(parseFloat(prevState?.gross_annual_amount || 0) + ((total_deduction?.employee_monthly_amount || 0) * 12)) || 0;
        const annualCtc = numberValidation(parseFloat(prevState?.gross_annual_amount || 0) + ((total_deduction?.employer_monthly_amount || 0) * 12)) || 0;
        const updatedFormData = {
            ...prevState,
            monthly_ctc: salary_calculation === "ctc" ? prevState?.monthly_ctc : annualCtc === 0 ? 0 : numberValidation(annualCtc / 12),
            annual_ctc: salary_calculation === "ctc" ? prevState?.annual_ctc : annualCtc,
            earnings: updatedEarnings,
            deductions: updatedDeductions,
            total_earning: total_earning,
            total_deduction: total_deduction,
            net_salary_monthly: numberValidation(net_salary_monthly),
            net_salary: numberValidation(net_salary_monthly * 12)
        };
        return updatedFormData;
    });
}, [formData?.annual_ctc, formData?.gross_annual_amount]);

  const handleChange = (name, value) => {
    let values = parseFloat(value || 0);
    setFormData((prevState) => {
      let updatedFormData = { ...prevState };
      switch (name) {
        case "monthly_ctc":
          updatedFormData.monthly_ctc = values;
          updatedFormData.annual_ctc = numberValidation(values * 12);
          focusMCTC();
          break;
        case "annual_ctc":
          updatedFormData.annual_ctc = values;
          updatedFormData.monthly_ctc = numberValidation(values / 12);
          focusACTC();
          break;
        case "gross_monthly_amount":
          updatedFormData.gross_monthly_amount = values;
          updatedFormData.gross_annual_amount = numberValidation(values * 12);
          focusMCTC();
          break;
        case "gross_annual_amount":
          updatedFormData.gross_annual_amount = values;
          updatedFormData.gross_monthly_amount = numberValidation(values / 12);
          focusACTC();
          break;
        default:
          updatedFormData[name] = value;
          break;
      }

      if (name === "monthly_ctc" || name === "annual_ctc" || name === "gross_monthly_amount" || name === "gross_annual_amount") {
        setIsInputDisable(values ? false : true);
        setIsEsicDisable(values ? false : true);
      }
      return updatedFormData;
    });
  };

  const handleAmountChange = (statekey, name, index, value) => {
    setFormData((prevState) => {
      const updatedFormData = [...prevState[statekey]];
      let values = parseFloat(value || 0);
      const annual_ctc = parseFloat(prevState?.annual_ctc || 0);
      const gross_annual_amount = parseFloat(prevState?.gross_annual_amount || 0);
      let updatedTotalEarnings = { ...prevState.total_earning };
      let updatedTotalDeductions = { ...prevState.total_deduction };

      if (statekey === "earnings" && name === "monthly_amount") {
        const basicComponent = prevState?.earnings?.find(component =>
          containsBasic(component?.name)
        );

        const basicAnnualAmount = basicComponent ? basicComponent?.annual_amount : 0;

        const referenceAmount = getReferenceAmount(updatedFormData[index], annual_ctc, basicAnnualAmount, gross_annual_amount);
        const isLastItem = index === updatedFormData.length - 1;

        if (isLastItem) {
          const totalAnnualExcludingLast = calculateTotalAnnualExcludingLast(updatedFormData, annual_ctc, basicAnnualAmount, gross_annual_amount);
          const calcValue = numberValidation((+gross_annual_amount || 0) - (+totalAnnualExcludingLast || 0));
          const percentage = (calcValue / referenceAmount) * 100;

          updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: numberValidation(calcValue / 12),
            percentage: percentage ? numberValidation(percentage).toString() : parseFloat(updatedFormData[index].percentage || 0).toString(),
            annual_amount: calcValue
          };
        } else {
          updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: numberValidation(values),
            percentage: (calculatePercentage(values, referenceAmount) || 0).toString(),
            annual_amount: numberValidation(values * 12)
          };
        }

        updatedTotalEarnings = calculateTotals("earnings", updatedFormData, { ...default_total_earnings });

        // Recalculate the last item in earnings components
        const lastIndex = updatedFormData.length - 1;
        const totalAnnualExcludingLast = calculateTotalAnnualExcludingLast(updatedFormData, annual_ctc, basicAnnualAmount, gross_annual_amount);
        const calcValue = numberValidation((+gross_annual_amount || 0) - (+totalAnnualExcludingLast || 0));
        const percentage = (calcValue / referenceAmount) * 100;

        updatedFormData[lastIndex] = {
          ...updatedFormData[lastIndex],
          monthly_amount: numberValidation(calcValue / 12),
          percentage: numberValidation(percentage).toString(),
          annual_amount: calcValue
        };
        updatedTotalEarnings = calculateTotals("earnings", updatedFormData, { ...default_total_earnings });

      } else if (statekey === "deductions") {
        const basicComponent = prevState?.earnings?.find(component =>
          containsBasic(component?.name)
        );
        const dearnessComponent = prevState?.earnings?.find(component =>
          containsDearnessAllowance(component?.name)
        );

        const basicAnnualAmount = basicComponent ? parseFloat(basicComponent?.annual_amount || 0) : 0;
        const basicMonthlyAmount = basicComponent ? parseFloat(basicComponent?.monthly_amount || 0) : 0;
        const dearnessAnnualAmount = dearnessComponent ? parseFloat(dearnessComponent?.annual_amount || 0) : 0;
        // const pfBaseAmount = numberValidation(basicAnnualAmount + dearnessAnnualAmount);
        const pfBaseAmount = sumAnnualAmountsExcludingHRA(prevState?.earnings);
        // Calculate total monthly earning
        const totalMonthlyEarnings = salary_calculation === "ctc" ?
          parseFloat(updatedTotalEarnings?.monthly_amount || 0) : parseFloat(prevState?.gross_monthly_amount);

        const handleDeduction = (percentageField) => {
          let percentage;
          if (containsProvidentFund(updatedFormData[index]["name"])) {
            if (basicMonthlyAmount < customConfig?.pf_limit) {
              percentage = (calculatePercentage(values, pfBaseAmount) || 0).toString();
            } else {
              const cap_amount = numberValidation(customConfig?.cap_amount || 0);
              percentage = (calculatePercentage(cap_amount, pfBaseAmount) || 0).toString();
              values = cap_amount;
            }
          } else if (containsESI(updatedFormData[index]["name"])) {
            // Only update ESIC value if total monthly earning is <= 21000
            if (totalMonthlyEarnings <= 21000) {
              percentage = (calculatePercentage(values, totalMonthlyEarnings * 12) || 0).toString();
              setIsEsicDisable(false);
            } else {
              percentage = "0"; // Exclude ESIC value
              values = 0;
              setIsEsicDisable(true);
            }
          } else if (containsTaxTerms(updatedFormData[index]["name"])) {
            percentage = calculatePercentage(values, basicAnnualAmount);
          } else {
            const salValue = getSalValue(customConfig.salary_deduction, prevState, annual_ctc, basicAnnualAmount);
            percentage = (calculatePercentage(values, salValue) || 0).toString();
          }
          updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: numberValidation(values),
            [percentageField]: percentage || "0",
            annual_amount: (percentageField === "employee_share_percentage")
              ? calculateAnnualAmount(values, numberValidation(updatedFormData[index]?.employer_share_monthly_amount))
              : calculateAnnualAmount(values, numberValidation(updatedFormData[index]?.employee_share_monthly_amount))
          };
        };

        if (name === "employee_share_monthly_amount") {
          handleDeduction("employee_share_percentage");
        } else if (name === "employer_share_monthly_amount") {
          handleDeduction("employer_share_percentage");
        }
        updatedTotalDeductions = calculateTotals("deductions", updatedFormData, { ...default_total_deduction });
      }

      const netSalaryMonthly = numberValidation((annual_ctc - numberValidation(updatedTotalDeductions?.annual_amount)) / 12);
      // calculate gross salary 
      if (salary_calculation === "ctc") {
        let grossSalaryAnnual = numberValidation(annual_ctc - numberValidation((updatedTotalDeductions?.employer_monthly_amount || 0) * 12));
        updatedTotalEarnings = {
          percentage: numberValidation(updatedTotalEarnings?.percentage || 0),
          monthly_amount: numberValidation(grossSalaryAnnual / 12),
          annual_amount: numberValidation(grossSalaryAnnual),
        }
      }
      // calculate ctc 
      // const annualCtc = numberValidation(parseFloat(gross_annual_amount || 0) + ((updatedTotalDeductions?.employee_monthly_amount || 0) * 12)) || 0;
      const annualCtc = numberValidation(parseFloat(gross_annual_amount || 0) + ((updatedTotalDeductions?.employer_monthly_amount || 0) * 12)) || 0;
      let stateObj = {
        ...prevState,
        [statekey]: updatedFormData,
        monthly_ctc: salary_calculation === "ctc" ? prevState.monthly_ctc : annualCtc === 0 ? 0 : numberValidation(annualCtc / 12),
        annual_ctc: salary_calculation === "ctc" ? prevState.annual_ctc : annualCtc,
        total_earning: { ...updatedTotalEarnings },
        total_deduction: { ...updatedTotalDeductions },
        net_salary_monthly: netSalaryMonthly,
        net_salary: numberValidation(netSalaryMonthly * 12),
      };
      return { ...stateObj };
    });
  };

  const handlePercentChange = (statekey, name, index, value) => {
    setFormData((prevState) => {
      const updatedFormData = [...prevState[statekey]];
      let percentage = parseFloat(value || 0);
      const annual_ctc = parseFloat(prevState?.annual_ctc || 0);
      const gross_annual_amount = parseFloat(prevState?.gross_annual_amount || 0);
      let updatedTotalEarnings = { ...prevState.total_earning };
      let updatedTotalDeductions = { ...prevState.total_deduction };

      if (statekey === "earnings" && name === "percentage") {
        const basicComponent = prevState?.earnings?.find(component =>
          containsBasic(component?.name)
        );
        const basicAnnualAmount = basicComponent ? basicComponent?.annual_amount : 0;
        const referenceAmount = getReferenceAmount(updatedFormData[index], annual_ctc, basicAnnualAmount, gross_annual_amount);
        const isLastItem = index === updatedFormData.length - 1;

        if (isLastItem) {
          const totalAnnualExcludingLast = calculateTotalAnnualExcludingLast(updatedFormData, annual_ctc, basicAnnualAmount, gross_annual_amount);
          const calcValue = numberValidation((+gross_annual_amount || 0) - (+totalAnnualExcludingLast || 0));
          percentage = (calcValue / referenceAmount) * 100;

          updatedFormData[index] = {
            ...updatedFormData[index],
            monthly_amount: numberValidation(calcValue / 12),
            percentage: percentage ? numberValidation(percentage).toString() : parseFloat(updatedFormData[index].percentage || 0).toString(),
            annual_amount: calcValue
          };
        } else {
          let calcValue = calculateAmountFromPercentage(percentage, referenceAmount);
          updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: value,
            monthly_amount: numberValidation(calcValue / 12),
            annual_amount: numberValidation(calcValue)
          };
        }
        updatedTotalEarnings = calculateTotals("earnings", updatedFormData, { ...default_total_earnings });

        // Recalculate the last item in earnings components
        const lastIndex = updatedFormData.length - 1;
        const totalAnnualExcludingLast = calculateTotalAnnualExcludingLast(updatedFormData, annual_ctc, basicAnnualAmount, gross_annual_amount);
        const calcValue = numberValidation((+gross_annual_amount || 0) - (+totalAnnualExcludingLast || 0));
        percentage = (calcValue / referenceAmount) * 100;

        updatedFormData[lastIndex] = {
          ...updatedFormData[lastIndex],
          monthly_amount: numberValidation(calcValue / 12),
          percentage: numberValidation(percentage).toString(),
          annual_amount: calcValue
        };

        updatedTotalEarnings = calculateTotals("earnings", updatedFormData, { ...default_total_earnings });
      } else if (statekey === "deductions") {
        const basicComponent = prevState?.earnings?.find(component =>
          containsBasic(component?.name)
        );
        const dearnessComponent = prevState?.earnings?.find(component =>
          containsDearnessAllowance(component?.name)
        );

        const basicAnnualAmount = basicComponent ? parseFloat(basicComponent?.annual_amount || 0) : 0;
        const basicMonthlyAmount = basicComponent ? parseFloat(basicComponent?.monthly_amount || 0) : 0;
        const dearnessAnnualAmount = dearnessComponent ? parseFloat(dearnessComponent?.annual_amount || 0) : 0;
        // const pfBaseAmount = numberValidation(basicAnnualAmount + dearnessAnnualAmount);
        const pfBaseAmount = sumAnnualAmountsExcludingHRA(prevState?.earnings);
        // Calculate total monthly earning
        const totalMonthlyEarnings = salary_calculation === "ctc" ? parseFloat(updatedTotalEarnings.monthly_amount || 0)
          : parseFloat(prevState?.gross_monthly_amount || 0);

        const handleDeduction = (amountField) => {
          let amount;
          if (containsProvidentFund(updatedFormData[index]["name"])) {
            if (basicMonthlyAmount < customConfig?.pf_limit) {
              amount = calculateAmountFromPercentage(percentage, pfBaseAmount);
            } else {
              const cap_amount = numberValidation(customConfig?.cap_amount || 0);
              amount = cap_amount;
              value = (calculatePercentage(cap_amount, pfBaseAmount) || 0).toString()
            }
          } else if (containsESI(updatedFormData[index]["name"])) {
            // Only update ESIC value if total monthly earning is <= 21000
            if (totalMonthlyEarnings <= 21000) {
              amount = calculateAmountFromPercentage(percentage, numberValidation(totalMonthlyEarnings));
              setIsEsicDisable(false);
            } else {
              amount = 0; // Exclude ESIC value
              value = "0";
              setIsEsicDisable(true);
            }
          } else if (containsTaxTerms(updatedFormData[index]["name"])) {
            amount = calculateAmountFromPercentage(percentage, basicAnnualAmount);
          } else {
            const salValue = getSalValue(customConfig.salary_deduction, prevState, annual_ctc, basicAnnualAmount);
            amount = calculateAmountFromPercentage(percentage, salValue);
          }

          updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: numberValidation(value).toString(),
            [amountField]: amount || 0,
            annual_amount: (amountField === "employee_share_monthly_amount")
              ? calculateAnnualAmount(amount, numberValidation(updatedFormData[index]?.employer_share_monthly_amount))
              : calculateAnnualAmount(amount, numberValidation(updatedFormData[index]?.employee_share_monthly_amount))
          };
        };

        if (name === "employee_share_percentage") {
          handleDeduction("employee_share_monthly_amount");
        } else if (name === "employer_share_percentage") {
          handleDeduction("employer_share_monthly_amount");
        }
        updatedTotalDeductions = calculateTotals("deductions", updatedFormData, { ...default_total_deduction });
      }

      const netSalaryMonthly = numberValidation((annual_ctc - numberValidation(updatedTotalDeductions?.annual_amount)) / 12);
      // calculate gross salary 
      if (salary_calculation === "ctc") {
        let grossSalaryAnnual = numberValidation(annual_ctc - numberValidation((updatedTotalDeductions?.employer_monthly_amount || 0) * 12));
        updatedTotalEarnings = {
          percentage: numberValidation(updatedTotalEarnings?.percentage || 0),
          monthly_amount: numberValidation(grossSalaryAnnual / 12),
          annual_amount: numberValidation(grossSalaryAnnual),
        }
      }
      // calculate ctc 
      // const annualCtc = numberValidation(parseFloat(gross_annual_amount || 0) + ((updatedTotalDeductions?.employee_monthly_amount || 0) * 12)) || 0;
      const annualCtc = numberValidation(parseFloat(gross_annual_amount || 0) + ((updatedTotalDeductions?.employer_monthly_amount || 0) * 12)) || 0;
      let stateObj = {
        ...prevState,
        [statekey]: updatedFormData,
        monthly_ctc: salary_calculation === "ctc" ? prevState.monthly_ctc : annualCtc === 0 ? 0 : numberValidation(annualCtc / 12),
        annual_ctc: salary_calculation === "ctc" ? prevState.annual_ctc : annualCtc,
        total_earning: { ...updatedTotalEarnings },
        total_deduction: { ...updatedTotalDeductions },
        net_salary_monthly: netSalaryMonthly,
        net_salary: numberValidation(netSalaryMonthly * 12),
      };
      return { ...stateObj };
    });
  }

  const handleTemplateChange = (value) => {
    setSalary_template_id(value);
  }

  const renderAmountInput = (statekey, name = "", index = "", isReadonly = false) => {
    const inputStyle = statekey === "monthly_ctc" || statekey === "annual_ctc" ||
      statekey === "gross_monthly_amount" || statekey === "gross_annual_amount";
    return (
      <Input
        autoComplete='off'
        style={{
          ...styles.ctcStyle,
          borderBottom: inputStyle ? "1px solid #04B7B1" : "1px solid #616161",
        }}
        ref={(statekey === "monthly_ctc" || statekey === "gross_monthly_amount") ? mctRef :
          (statekey === "annual_ctc" || statekey === "gross_annual_amount") ? actRef : null}
        prefix="₹"
        type={"text"}
        name={name === "" ? statekey : name}
        value={index === "" ? +formData[statekey] || 0 : +formData[statekey][index][name] || 0}
        maxLength={7}
        onChange={(e) => index !== "" ? handleAmountChange(statekey, e.target.name, index, +e.target.value)
          : handleChange(e.target.name, +e.target.value)}
        disabled={isReadonly}
        onKeyDown={handleOnKeyNumeric}
      />
    )
  }

  const renderPercentInput = (statekey, name, index = "", isReadonly = false) => {
    return (
      <Input
        autoComplete='off'
        style={{
          ...styles.ctcStyle,
          borderBottom: "1px solid #616161",
        }}
        prefix="%"
        type={"text"}
        name={name}
        value={formData[statekey][index][name] || "0"}
        maxLength={7}
        onChange={(e) => handlePercentChange(statekey, e.target.name, index, e.target.value)}
        disabled={isReadonly}
        onKeyDown={handleOnKeyDecimal}
      />
    )
  }

  const renderTlt = () => {
    return (
      <div className='w-full grid grid-cols-4 gap-x-10 py-2 border-b'>
        <p className='salaryTemp_txt'>Salary Components</p>
        <p className='salaryTemp_txt'>Calculation Type</p>
        <p className='salaryTemp_txt'>Monthly Amount</p>
        <p className='salaryTemp_txt'>Annual Amount</p>
      </div>
    )
  }

  const isCtcEditable = !salary_template_id;

  return (
    <Card style={{ height: "100%", overflow: "hidden", position: "relative" }}>
      <div className="flex justify-between items-center mb-4 border-b py-1" style={{ height: "5dvh" }}>
        <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Salary BreakUp Calculator</p>
        <div style={{ width: "16dvw" }}>
          <CommonGroupSelect
            groupedData={dropdownList?.salaryTemplateList}
            onChange={handleTemplateChange}
            value={salary_template_id}
            placeholder={"Select Template"}
            valueKey={"id"}
            showKey={"salary_template_name"}
          />
        </div>
      </div>
      <div style={{ height: "81dvh", overflowY: "scroll" }}>
        <Form
          layout="horizontal"
          style={{ width: "100%" }}
          name="basicform"
          ref={formRef}
          requiredMark={false}
          colon={false}
          initialValues={formData}
          form={form}
        >
          <Card>
            {salary_calculation === "ctc" ?
              <div className="w-full grid grid-cols-4 gap-x-10 py-2 border-b">
                <p className='salaryTemp_tlt'>Monthly CTC <span className='requiredTxt'>*</span></p>
                <Form.Item
                  name="monthly_ctc"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  {renderAmountInput("monthly_ctc", "", "", isCtcEditable)}
                </Form.Item>
                <p className='salaryTemp_tlt'>Annual CTC <span className='requiredTxt'>*</span></p>
                <Form.Item
                  name="annual_ctc"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  {renderAmountInput("annual_ctc", "", "", isCtcEditable)}
                </Form.Item>
              </div> :
              <div className="w-full grid grid-cols-4 gap-x-10 py-2 border-b">
                <p className='salaryTemp_tlt'>Gross Salary Per Month </p>
                <Form.Item
                  name="gross_monthly_amount"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  {renderAmountInput("gross_monthly_amount", "", "", isCtcEditable)}
                </Form.Item>
                <p className='salaryTemp_tlt'>Gross Salary Per Annum</p>
                <Form.Item
                  name="gross_annual_amount"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  {renderAmountInput("gross_annual_amount", "", "", isCtcEditable)}
                </Form.Item>
              </div>
            }
            {/* Earning Components start */}
            <div className='w-full flex justify-between items-center py-2 border-b'>
              <p className='salaryTemp_tlt'>Earning Components</p>
            </div>
            {renderTlt()}
            {
              formData?.earnings?.length > 0 ?
                formData?.earnings?.map((item, index) => {
                  const isHra = containsHRA(item.name);
                  return (
                    <div className='w-full grid grid-cols-4 gap-x-10 py-2' key={index}>
                      <Form.Item
                        style={{ textAlign: "left" }}
                        name={["earnings", index, "name"]}
                      >
                        <p className='salaryTemp_txt'>{`${item?.name} ${isHra ? " (From Basic)" : "(From Gross)"}`}</p>
                      </Form.Item>
                      <Form.Item
                        name={["earnings", index, "percentage"]}
                        rules={[{ required: true, message: "This field is required" }]}
                      >
                        {renderPercentInput("earnings", "percentage", index, isInputDisable)}
                      </Form.Item>
                      <Form.Item
                        name={["earnings", index, "monthly_amount"]}
                        rules={[{ required: true, message: "This field is required" }]}
                      >
                        {renderAmountInput("earnings", "monthly_amount", index, isInputDisable)}
                      </Form.Item>
                      <Form.Item
                        name={["earnings", index, "annual_amount"]}
                        rules={[{ required: true, message: "This field is required" }]}
                      >
                        {renderAmountInput("earnings", "annual_amount", index, true)}
                      </Form.Item>
                    </div>
                  )
                })
                : <div
                  className="w-full flex justify-center items-center subEmployee_details_content border-b"
                  style={{ height: "20dvh" }}>
                  No Data Found
                </div>
            }
            <div className="w-full grid grid-cols-4 gap-x-10 py-2">
              {salary_calculation === "ctc" ?
                <>
                  <p className='salaryTemp_tlt'>Gross Salary</p>
                  <p className='salaryTemp_tlt'>
                    {/* {`${formData?.total_earning?.percentage || 0} %`} */}
                  </p>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.total_earning?.monthly_amount || 0}`}</p>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.total_earning?.annual_amount || 0}`}</p>
                </> :
                <>
                  <p className='salaryTemp_tlt'>Monthly CTC</p>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.monthly_ctc || 0}`}</p>
                  <p className='salaryTemp_tlt'>Annual CTC</p>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.annual_ctc || 0}`}</p>
                </>
              }
            </div>
            {/* Earning Components End */}
          </Card>
          <div className="pt-2">
            <Card>
              {/* Deduction Components start */}
              <div className='w-full flex justify-between items-center py-2 border-b'>
                <p className='salaryTemp_tlt'>Deduction Components</p>
              </div>
              {renderTlt()}
              {formData?.deductions?.length > 0 ?
                <div className="grid grid-cols-4 gap-x-10">
                  <div></div>
                  <div className="grid grid-cols-2 gap-x-10">
                    <p className="pt-3 salaryTemp_txt">Employee Share</p>
                    <p className="pt-3 salaryTemp_txt">Employer Share</p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-10">
                    <p className="pt-3 salaryTemp_txt">Employee Share</p>
                    <p className="pt-3 salaryTemp_txt">Employer Share</p>
                  </div>
                </div> : null}
              {
                formData?.deductions?.length > 0 ?
                  formData?.deductions?.map((item, index) => {
                    const isEsi = containsESI(item?.name);
                    const isDeductEditable = isEsi ? isEsicDisable : (isInputDisable);
                    return (
                      <div className="grid grid-cols-4 gap-x-10 py-2" key={index}>
                        <Form.Item
                          style={{ textAlign: "left" }}
                          name={["deductions", index, "name"]}
                        >
                          <p className='salaryTemp_txt'>{`${item?.name} ${item?.name == "PF" ? "(From Earnings Except HRA)" : "(From Gross)"}`}</p>
                        </Form.Item>
                        <div className='grid grid-cols-2 gap-x-10'>
                          {item?.is_employee_share ? <Form.Item
                            name={["deductions", index, "employee_share_percentage"]}
                            rules={[{ required: true, message: "This field is required" }]}
                          >
                            {renderPercentInput("deductions", "employee_share_percentage", index, isDeductEditable)}
                          </Form.Item> : <Form.Item />}
                          {item?.is_employer_share ? <Form.Item
                            name={["deductions", index, "employer_share_percentage"]}
                            rules={[{ required: true, message: "This field is required" }]}
                          >
                            {renderPercentInput("deductions", "employer_share_percentage", index, isDeductEditable)}
                          </Form.Item> : <Form.Item />}
                        </div>
                        <div className='grid grid-cols-2 gap-x-10'>
                          {item?.is_employee_share ?
                            <Form.Item
                              name={["deductions", index, "employee_share_monthly_amount"]}
                              rules={[{ required: true, message: "This field is required" }]}
                            >
                              {renderAmountInput("deductions", "employee_share_monthly_amount", index, isDeductEditable)}
                            </Form.Item> : <Form.Item />}
                          {item?.is_employer_share ? <Form.Item
                            name={["deductions", index, "employer_share_monthly_amount"]}
                            rules={[{ required: true, message: "This field is required" }]}
                          >
                            {renderAmountInput("deductions", "employer_share_monthly_amount", index, isDeductEditable)}
                          </Form.Item> : <Form.Item />}
                        </div>
                        <Form.Item
                          name={["deductions", index, "annual_amount"]}
                          rules={[{ required: true, message: "This field is required" }]}
                        >
                          {renderAmountInput("deductions", "annual_amount", index, true)}
                        </Form.Item>
                      </div>
                    )
                  })
                  : <div
                    className="w-full h-2/5 flex justify-center items-center subEmployee_details_content border-b"
                    style={{ height: "20dvh" }} >
                    No Data Found
                  </div>
              }
              <div className="w-full grid grid-cols-4 gap-x-10 py-2 border-b">
                <p className='salaryTemp_tlt'>Total Deduction</p>
                <div className='w-full grid grid-cols-2 gap-x-5'>
                  <p className='salaryTemp_tlt'>
                    {/* {`${formData?.total_deduction?.percentage || 0} %`} */}
                  </p>
                </div>
                <div className='w-full grid grid-cols-2 gap-x-5'>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.total_deduction?.employee_monthly_amount || 0}`}</p>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.total_deduction?.employer_monthly_amount || 0}`}</p>
                </div>
                <p className='salaryTemp_tlt'>{`₹ ${formData?.total_deduction?.annual_amount || 0}`}</p>
              </div>
              <div className='w-full grid grid-cols-4 gap-x-10 py-2'>
                <p className='salaryTemp_tlt'>Net Salary</p>
                <p className='salaryTemp_tlt'></p>
                <p className='salaryTemp_tlt'>{`₹ ${formData?.net_salary_monthly || 0}`}</p>
                <p className='salaryTemp_tlt'>{`₹ ${formData?.net_salary || 0}`}</p>
              </div>
              {/* Deduction Components End */}
            </Card>
          </div>
        </Form>
      </div>
    </Card>
  );
}

const styles = {
  ctcStyle: {
    width: "100%",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderRadius: "0px",
  }
}

export default SalaryCalculator;