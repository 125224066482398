import React, { useState, useEffect, useCallback, useRef } from 'react';
import MyButton from 'components/ui/Button/MyButton';
import { Input, Card, Spin, Checkbox, Tooltip, Form, Select } from 'antd'
import ToastMsg from "components/common/ToastMsg";
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { ImagePaths } from "utils/ImagePath";
import { useNavigate, useLocation } from 'react-router-dom';
import "../configuration.css";
import {
  noBlankSpacesValidator,
  areValuesNotEmptyExceptKeys,
  handleOnKeyDecimal,
  handleOnKeyNumeric,
  numberValidation
} from 'components/common/validation';
import { salaryComponentType } from 'components/common/enum.js';
import { getSalaryComponentList } from "hooks/api/configurationApi/payrollPeriodApi";
import IconButton from "components/ui/Button/IconButton";
import { SALARY_TEMPLATE } from "constants";
import { default_total_earnings, default_total_deduction } from "components/common/enum";
import { CommonCancelPopup, CommonDeletePopup } from "components/common/CommonComponent";
import { math } from '@amcharts/amcharts5';

const AddEditSalaryTemplate = () => {
  const [formData, setFormData] = useState({
    is_active: true,
    code: "",
    salary_template_name: "",
    salary_department: "",
    salary_designation: "",
    monthly_ctc: 0,
    annual_ctc: 0,
    earning_components: [],
    total_earning: { ...default_total_earnings },
    deduction_components: [],
    total_deduction: { ...default_total_deduction },
    net_salary: 0,
    // add new components
    component_type: "",
    components: [],
    is_edit: false,
    edit_component: []
  });
  const [dropdownList, setDropDownList] = useState({
    designationList: [],
    departmentlist: [],
    componentList: []
  });
  const [loadings, setLoadings] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [omittedValues, setOmittedValues] = useState([]);
  const prevPropsRef = useRef();
  const refs = useRef({});
  // const salary_calculation = sessionStorage.getItem("salary_calculation") // "ctc" or "gross";
  const salary_calculation = "gross";
  //const [isalert, setIsAlert] = useState(false); //as discussed
  const [isCancelClk, setIsCancelClk] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { fromEdit, editDetails, isActive } = location.state || {};
  const [form] = Form.useForm();

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      let updateData = {
        is_active: editDetails?.is_active,
        code: editDetails?.code ? editDetails?.code : "",
        salary_template_name: editDetails?.salary_template_name ? editDetails?.salary_template_name : "",
        salary_department: editDetails?.department ? editDetails?.department?.id : "",
        salary_designation: editDetails?.designation ? editDetails?.designation?.id : "",
        monthly_ctc: editDetails?.monthly_ctc ? editDetails?.monthly_ctc : 0,
        annual_ctc: editDetails?.annual_ctc ? editDetails?.annual_ctc : 0,
        earning_components: editDetails?.earning_components ? editDetails?.earning_components : [],
        total_earning: { ...editDetails?.total_earning },
        deduction_components: editDetails?.deduction_components ? editDetails?.deduction_components : [],
        total_deduction: { ...editDetails?.total_deduction },
        net_salary: editDetails?.net_salary ? editDetails?.net_salary : 0,
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        ...updateData
      }));
      form.setFieldsValue({ ...updateData });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails, form, formData])

  const getEmployeeDesignationList = async () => {
    try {
      const apiData = await getEmployeeDesignation(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          designationList: [...apiData.data]
        }))
      } else {
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          designationList: []
        }))
      }
    } catch (error) {
      setDropDownList((prevFilterData) => ({
        ...prevFilterData,
        designationList: []
      }))
    }
  }

  const getDepartmentList = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          departmentlist: [...apiData.data]
        }))
      } else {
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          departmentlist: []
        }))
      }
    } catch (error) {
      setDropDownList((prevFilterData) => ({
        ...prevFilterData,
        departmentlist: []
      }))
    }
  }

  const getSalaryComponent = async () => {
    try {
      const apiData = await getSalaryComponentList(`drop_down=True`);
      if (apiData && apiData?.status === "success" && apiData?.data) {
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          componentList: [...apiData?.data]
        }))
      } else {
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          componentList: []
        }))
      }
    } catch (error) {
      setDropDownList((prevFilterData) => ({
        ...prevFilterData,
        componentList: []
      }))
    }
  }

  useEffect(() => {
    getEmployeeDesignationList();
    getDepartmentList();
    getSalaryComponent();
  }, []);

  useEffect(() => {
    if (dropdownList?.componentList?.length > 0) {
      handleUpdateDefaultComponent();
    }
  }, [dropdownList.componentList]);

  const calculateTotals = (stateKey, updatedComponents, totalObj) => {
    return updatedComponents?.reduce((totals, component) => {
      if (stateKey === "earning_components") {
        totals.percentage += numberValidation(parseFloat(component?.percentage || 0));
        totals.monthly_amount += numberValidation(parseFloat(component?.monthly_amount || 0));
        totals.annual_amount += numberValidation(parseFloat(component?.annual_amount || 0));
      } else if (stateKey === "deduction_components") {
        totals.percentage += numberValidation(parseFloat(component?.employee_share_percentage || 0));
        totals.employee_monthly_amount += numberValidation(parseFloat(component?.employee_share_monthly_amount || 0));
        totals.employer_monthly_amount += numberValidation(parseFloat(component?.employer_share_monthly_amount || 0));
        // Calculate annual_amount based on monthly amounts
        totals.annual_amount += numberValidation((parseFloat(component?.employee_share_monthly_amount || 0) +
          parseFloat(component?.employer_share_monthly_amount || 0)) * 12);
      }
      return totals;
    }, { ...totalObj });
  }

  const handleChange = (name, value) => {
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    form.setFieldsValue(updatedFormData);
  };

  const handleAmountChange = (statekey, name, index, value) => {
    setFormData((prevState) => {
      const updatedComponents = [...prevState[statekey]];
      updatedComponents[index] = {
        ...updatedComponents[index],
        [name]: parseFloat(value || 0),
      };
      form.setFieldsValue({
        [statekey]: updatedComponents
      });
      // Return the updated state
      return { ...prevState, [statekey]: updatedComponents };
    });
  }

  const handlePercentChange = (statekey, name, index, value) => {
    setFormData((prevState) => {
      const updatedComponents = [...prevState[statekey]];
      let updatedValue = value;
      if(prevState[statekey][index][name] !== parseFloat(value)){
         updatedValue = parseFloat(value);
      }
      if (isNaN(updatedValue)) {
        updatedValue = 0;
      }

        updatedComponents[index] = {
          ...updatedComponents[index],
          [name]: updatedValue,
        };
      
      

      let updatedTotalEarnings = { ...prevState.total_earning };
      let updatedTotalDeductions = { ...prevState.total_deduction };
      let totalearnper = 0;
      let calcfun = false;
      updatedComponents.forEach((component) => {
          if(component.name !== "HRA" && component.name != "Other allowence"){
            totalearnper += parseFloat(component.percentage) || 0;
          }
      })
      if(totalearnper > 100) {
        updatedComponents[index][name] = prevState[statekey][index][name] || 0;
        calcfun = true;
      }
      
      if (statekey === "earning_components") {
        if(!calcfun){
          recalculateComponents(updatedComponents);
        }
        updatedTotalEarnings = calculateTotals("earning_components", updatedComponents, { ...default_total_earnings });
      } else if (statekey === "deduction_components") {
        updatedTotalDeductions = calculateTotals("deduction_components", updatedComponents, { ...default_total_deduction });
      }

      const updatedState = {
        ...prevState,
        [statekey]: updatedComponents,
        total_earning: { ...updatedTotalEarnings },
        total_deduction: { ...updatedTotalDeductions },
      };
      form.setFieldsValue(updatedState);
      // Return the updated state
      return updatedState;
    });
  }

  const updateComponent = (component) => {
    if (component?.component_type === "earning") {
      return {
        id: component?.id,
        name: component?.salary_component_name,
        percentage: 0,
        monthly_amount: 0,
        annual_amount: 0
      };
    } else if (component?.component_type === "deduction") {
      return {
        id: component?.id,
        name: component?.salary_component_name,
        is_employee_share: component?.employee_share,
        is_employer_share: component?.employer_share,
        employee_share_percentage: 0,
        employer_share_percentage: 0,
        employee_share_monthly_amount: 0,
        employer_share_monthly_amount: 0,
        annual_amount: 0,
        is_amount: component?.amount,
      };
    }
    return null;
  };

  const handleAddComponent = () => {
    let { component_type, components, edit_component, earning_components, deduction_components } = formData;
    let updatedComponents = formData?.is_edit ? [] :
      component_type === "earning" ? [...earning_components] :
        [...deduction_components];

    // Update components based on edit_component or dropdownList
    components?.forEach((componentId) => {
      const existingComponent = edit_component?.find(item => item?.id === componentId);
      if (existingComponent) {
        updatedComponents.push(existingComponent);
      } else {
        const component = dropdownList?.componentList?.find(item => item?.id === componentId);
        if (component) {
          const updatedComponent = updateComponent(component);
          if (updatedComponent) {
            updatedComponents.push(updatedComponent);
          }
        }
      }
    });

    let updatedTotalEarnings = { ...formData?.total_earning };
    let updatedTotalDeductions = { ...formData?.total_deduction };

    if (component_type === "earning") {
      updatedTotalEarnings = calculateTotals("earning_components", updatedComponents, { ...default_total_earnings });
    } else if (component_type === "deduction") {
      updatedTotalDeductions = calculateTotals("deduction_components", updatedComponents, { ...default_total_deduction });
    }

    const updatedFormData = {
      ...formData,
      [component_type === "earning" ? "earning_components" : "deduction_components"]: updatedComponents,
      total_earning: { ...updatedTotalEarnings },
      total_deduction: { ...updatedTotalDeductions },
      component_type: "",
      components: [],
      is_edit: false,
      edit_component: []
    }
    form.setFieldsValue(updatedFormData);
    setFormData(updatedFormData);
  };

  const handleUpdateDefaultComponent = async () => {
    if (!fromEdit) {
      let updatedEarningComponents = [];
      let updatedDeductionComponents = [];

      dropdownList?.componentList?.forEach((component) => {
        let updatedComponent = null;
        let componentType = null;
        if(component?.is_default){
            updatedComponent = updateComponent(component);
            componentType = component?.component_type;  
        }
        if (updatedComponent) {
          if (componentType === "earning") {
            updatedEarningComponents.push(updatedComponent);
          } else if (componentType === "deduction") {
            updatedDeductionComponents.push(updatedComponent);
          }
        }
      });

      const updatedFormData = {
        ...formData,
        earning_components: updatedEarningComponents,
        deduction_components: updatedDeductionComponents,
        total_earning: calculateTotals("earning_components", updatedEarningComponents, { ...default_total_earnings }),
        total_deduction: calculateTotals("deduction_components", updatedDeductionComponents, { ...default_total_deduction }),
        component_type: "",
        components: [],
        is_edit: false,
        edit_component: []
      };

      form.setFieldsValue(updatedFormData);
      setFormData(updatedFormData);
    }
  };


  const recalculateComponents = (updatedComponents) => {
    const nonHraComponents = updatedComponents;
    let totalNonHraPercentage = 0;
    let othersvalue = 0;
    let compid;
    let otheradd = false;
    let hraPercent = 0
    nonHraComponents.forEach((component,index) => {
      if(component.name !== "HRA" && component.name !== "Other allowence" ){
        const componentPercentage = parseFloat(component.percentage) || 0;
        totalNonHraPercentage += componentPercentage;
      }
      if(component.name === "HRA"){
        hraPercent = parseFloat(component.percentage) || 0;
      }
      if(component.name === "Other allowence"){
        compid = index;
        otheradd = true;
      }
    });
    if (nonHraComponents.length > 0) {
      if (totalNonHraPercentage < 100){
        let hraGross = (hraPercent/100)*(parseFloat(totalNonHraPercentage))
        othersvalue = 100 - parseFloat(totalNonHraPercentage) - parseFloat(hraGross);
      }
      if(otheradd){
        nonHraComponents[compid].percentage = othersvalue.toFixed(2);
      }  
    }
  
    const updatedFormData = {
      ...formData,
      earning_components: [...nonHraComponents].filter(Boolean),
      total_earning: calculateTotals(
        "earning_components",
        [...nonHraComponents].filter(Boolean),
        { ...default_total_earnings }
      ),
    };
  
    setFormData(updatedFormData);
    form.setFieldsValue(updatedFormData);
  };

  const handleComponentsEdit = (type, component) => {
    const updatedFormData = {
      ...formData,
      component_type: type,
      components: component?.map(item => item?.id),
      is_edit: true,
      edit_component: component
    }
    form.setFieldsValue(updatedFormData);
    setFormData(updatedFormData);
  }

  const renderInput = (label, statekey, maxLength = 150, isReadonly = false, required = false) => {
    return (
      <>
        <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
        <Input
          autoComplete='off'
          style={{ width: "100%" }}
          type={"text"}
          name={statekey}
          value={formData[statekey]}
          maxLength={maxLength}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          disabled={isReadonly}
        />
      </>
    )
  }

  const renderAmountInput = (statekey, name = "", index = "", isReadonly = false) => {
    const inputStyle = statekey === "monthly_ctc" || statekey === "annual_ctc";
    return (
      <Input
        autoComplete='off'
        // className='custom_amount_input'
        style={{
          ...styles.ctcStyle,
          borderBottom: inputStyle ? "1px solid #04B7B1" : "1px solid #616161",
        }}
        prefix="₹"
        type={"text"}
        name={name === "" ? statekey : name}
        value={index === "" ? formData[statekey] || 0 : formData[statekey][index][name] || 0}
        maxLength={7}
        onChange={(e) => index !== "" ? handleAmountChange(statekey, e.target.name, index, +e.target.value)
          : handleChange(e.target.name, +e.target.value)}
        disabled={isReadonly}
        onKeyDown={handleOnKeyNumeric}
      />
    )
  }

  const renderPercentInput = (statekey, name, index = "", isReadonly = false, compname = "") => {
    return (
      <Input
        autoComplete='off'
        // className='custom_amount_input'
        style={{
          ...styles.ctcStyle,
          borderBottom: "1px solid #616161",
        }}
        prefix="%"
        type={"text"}
        name={name}
        value={index === "" ? formData[statekey] || 0 : formData[statekey][index][name] || 0}
        maxLength={5}
        onChange={(e) => handlePercentChange(statekey, e.target.name, index, e.target.value)}
        disabled={isReadonly}
        onKeyDown={handleOnKeyDecimal}
      />      
    )
  }

  const handleToggleTooltip = (visible, values, position) => {
    setShowTooltip(visible);
    setOmittedValues(values);
    setTooltipPosition(position);
  };

  const handleMouseEnter = (event, omittedValues) => {
    const rect = event.target.getBoundingClientRect();
    handleToggleTooltip(true, omittedValues, { x: rect.left, y: rect.bottom });
  };

  const handleMouseLeave = () => {
    handleToggleTooltip(false, [], { x: 0, y: 0 });
  };

  const renderMaxTagPlaceholder = (omittedValues) => (
    <div
      onMouseEnter={(event) => handleMouseEnter(event, omittedValues)}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: 'pointer' }}
    >
      +{omittedValues.length}
    </div>
  );

  const renderSelect = (label, statekey, list, showKey = "text",
    valueKey = "value", isDisabled = false,
    required = false, isMulti = false,
    restrictArray = [], isRestricted = false) => {
    if (!refs?.current[statekey]) {
      refs.current[statekey] = React.createRef();
    }
    return (
      <>
        <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
        <Select
          showSearch
          mode={isMulti ? "multiple" : null}
          showArrow="true"
          maxTagCount={2}
          maxTagTextLength={6}
          style={{ width: "100%" }}
          value={formData[statekey] ? formData[statekey] : undefined}
          name={statekey}
          onChange={(value) => handleChange(statekey, value)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          allowClear={!formData?.is_edit}
          disabled={isDisabled}
          maxTagPlaceholder={(omittedValues) => renderMaxTagPlaceholder(omittedValues)}
          ref={statekey !== "components"? refs.current[statekey]: null}
          onSelect={() => {
            if(statekey !== "components"){
              refs?.current[statekey]?.current?.blur();
            }
          }}  
        >
          {list?.map((option) => (
            (((valueKey === "id" && (option?.is_active === true || option?.id === formData[statekey] )) || valueKey !== "id") &&
              !(isRestricted && restrictArray?.includes(option[valueKey]))) ?
              <Select.Option key={option[valueKey]} value={option[valueKey]} disabled={option?.is_default}>{option[showKey]}</Select.Option>
              : null
          ))}
        </Select>
        {omittedValues?.length > 0 && showTooltip && (
          <div className="commonTooltipOfMutliSlt"
            style={{ position: 'fixed', top: tooltipPosition?.y + 10, left: tooltipPosition?.x }}>
            <ul className="commonTooltipOfMutliSltDiv">
              {omittedValues?.map(value => (
                <li key={value?.key} className="commonTooltipOfMutliSltTxt" style={{ width: "8dvw" }}>{value?.label}</li>
              ))}
            </ul>
          </div>
        )}
      </>
    )
  }

  const renderTlt = () => {
    return (
      <div className='w-full grid grid-cols-4 gap-x-10 py-3 border-b'>
        <p className='salaryTemp_txt'>Salary Components</p>
        <p className='salaryTemp_txt'>Calculation Type</p>
        <p className='salaryTemp_txt'>Monthly Amount</p>
        <p className='salaryTemp_txt'>Annual Amount</p>
      </div>
    )
  }

  const handleBackCancel = useCallback(() => {
    form.resetFields();
    navigate('/configuration/salarytemplate');
  }, [navigate, form]);

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token");
    try {
      setLoadings(true)
      let updatedFormData = { ...formData };
      updatedFormData = {
        ...updatedFormData,
        department: updatedFormData?.salary_department,
        designation: updatedFormData?.salary_designation ? updatedFormData?.salary_designation : "",
        is_draft: isDraftClked ? true : false
      };
      delete updatedFormData.component_type;
      delete updatedFormData.components;
      delete updatedFormData.is_edit;
      delete updatedFormData.edit_component;
      delete updatedFormData.salary_department;
      delete updatedFormData.salary_designation;

      fetch(SALARY_TEMPLATE, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoadings(false);
            setFormData({
              is_active: true,
              code: "",
              salary_template_name: "",
              salary_department: "",
              salary_designation: "",
              monthly_ctc: 0,
              annual_ctc: 0,
              earning_components: [],
              total_earning: { ...default_total_earnings },
              deduction_components: [],
              total_deduction: { ...default_total_deduction },
              net_salary: 0,
              // add new components
              component_type: "",
              components: [],
              is_edit: false,
              edit_component: []
            })
          }
          else if (data.status === "fail") {
            setLoadings(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoadings(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData, fromEdit, handleBackCancel]);

  const checkAllMandatory = useCallback((isDraftClked) => {
    if (!isDraftClked) {
      if (formData?.earning_components?.length === 0) {
        ToastMsg("error", "Earning Components are required.");
        return;
      }
      if (formData?.deduction_components?.length === 0) {
        ToastMsg("error", "Deduction Components are required.");
        return;
      }
      let totalvalue = 0;
      let errorflag = false;
      let basicPercent = 0;
      formData.earning_components.forEach((item) => {
        if(item?.name === "Basic"){
          if(item.percentage < 40 || item.percentage > 50){
            ToastMsg("error", "Basic Value should be 40% to 50%");
            errorflag = true;
          }
        }
        if(item?.name === "Basic"){
          basicPercent = parseFloat(item?.percentage)
        }
        if(item?.name === "HRA"){
          if(item.percentage < 40 || item.percentage > 50){
            ToastMsg("error", "HRA Value should be 40% to 50%");
            errorflag = true;
          }else{
            totalvalue += ((parseFloat(item?.percentage)/100)*(basicPercent));
          }
        }
        if(item?.name !== "HRA"){
          totalvalue += parseFloat(item?.percentage);
        }
      })
      if(math.round(totalvalue) !== 100){
        ToastMsg("error", "Earning Components value should be 100%");
        errorflag = true;
      }
      formData.deduction_components.forEach((item) => {
        if(item?.name === "PF"){
          if(item.employee_share_percentage > 12){
            ToastMsg("error", "Employee Share should be below 12%");
            errorflag = true;
          }
          if(item.employer_share_percentage > 12){
            ToastMsg("error", "Employer Share should be below 12%");
            errorflag = true;
          }
        }
      })
      if(!errorflag){
        form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
      }      
    } else {
      handleSubmit(true);
    }
  }, [form, handleSubmit]);

  const handleSave = useCallback(() => {
    //if(!formData.is_active){ //as discussed
      //setIsAlert(true);
    //}else{
      checkAllMandatory(false);
    //}
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const handleCancelBtn = () => {
    setIsCancelClk(false);
    //setIsAlert(false); //as discussed
    handleBackCancel();
  }

  const handleSaveInactive = useCallback(() => {
    checkAllMandatory(false);
   // setIsAlert(false); //as discussed
  }, [checkAllMandatory]);

  const keysToExclude = ['is_active', 'total_earning', 'total_deduction', 'is_edit'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);
  const isAddDisable = ((!formData?.component_type) || formData?.components?.length === 0);

  const filteredComponentList = formData?.component_type
    ? dropdownList?.componentList?.filter(item => item?.component_type === formData?.component_type)
    : dropdownList?.componentList;

  const restrictComponentList = formData?.component_type
    ? formData?.component_type === "earning"
      ? formData?.earning_components?.map(item => item?.id)
      : formData?.component_type === "deduction"
        ? formData?.deduction_components?.map(item => item?.id)
        : []
    : [];

  return (
    <Card style={{ height: "100%", overflow: "hidden", position: "relative" }}>
      {loadings && (
        <div className="loaderOverlay">
          <Spin />
        </div>
      )}
      <div className='flex justify-between items-center border-b'>
        <div className="flex items-center cursor-pointer">
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default}
              alt="Back" className='commonTblBackIcon'
              onClick={handleBackCancel} />
          </Tooltip>
          <p className='runpayRoll_Txt py-1'>Add Salary Template</p>
        </div>
        <Checkbox
          disabled={fromEdit ? !isActive : true}
          checked={formData?.is_active}
          onChange={(e) => handleChange("is_active", e.target.checked)}
        >
          Active
        </Checkbox>
      </div>
      <Form
        layout="inline"
        style={{ height: "81dvh" }}
        form={form}
        colon={false}
        requiredMark={false}
        className='w-full'
        name="basicform"
        onFinish={handleSave}
        onSubmit={(e) => e.preventDefault()}
        initialValues={formData}
      >
        <div className='w-full' style={{ height: "76dvh" }}>
          <div
            className={`w-full grid ${fromEdit ? "grid-rows-2" : "grid-row-1"} grid-cols-3 gap-3 border-b pb-4 mt-2`}>
            {fromEdit ?
              <Form.Item
                name="code"
                rules={[{ required: true, message: "This field is required" },
                { validator: noBlankSpacesValidator }
                ]}
              >
                {renderInput('Salary Template Code', "code", 10, true, true)}
              </Form.Item>
              : null}
            <Form.Item
              name="salary_template_name"
              rules={[{ required: true, message: "This field is required" },
              { validator: noBlankSpacesValidator }
              ]}
            >
              {renderInput('Salary Template Name', "salary_template_name", 50, false, true)}
            </Form.Item>
            <Form.Item
              name={"salary_department"}
              rules={[{ required: true, message: "This field is required" }]}
            >
              {renderSelect('Select Department', "salary_department", dropdownList?.departmentlist, "department_name", "id", false, true)}
            </Form.Item>
            <Form.Item name="notes" >
              {renderSelect('Select Designation', "salary_designation", dropdownList?.designationList, "designation_name", "id", false, false)}
            </Form.Item>
          </div>
          <p className='salaryTemp_tlt mt-2' >Add Components</p>
          <div className="w-full grid grid-cols-3 gap-3 border-b pb-4 mt-2 items-end">
            <Form.Item name={"component_type"}>
              {renderSelect('Component Type', "component_type", salaryComponentType, "text", "value", formData?.is_edit)}
            </Form.Item>
            <Form.Item name="components">
              {renderSelect('Component', "components", filteredComponentList, "salary_component_name", "id", !formData?.component_type, false, true, restrictComponentList, !formData?.is_edit)}
            </Form.Item>
            <Form.Item >
              <MyButton htmlType="button" label={"Add"}
                bgColor={isAddDisable ? "#cbcbcb" : "#334B49"}
                disabled={isAddDisable}
                onClick={handleAddComponent}
                loading={loadings} paddingX={"0 1.2vw"} />  
            </Form.Item>
          </div>
          <p className='salaryTemp_tlt py-2 border-b'>Salary Structure</p>
          <div className='w-full overflow-y-auto overflow-x-hidden' style={{ height: fromEdit ? "33dvh" : "42dvh" }}>
            <div className="w-full grid grid-cols-4 gap-x-10 py-3 border-b">
              <p className='salaryTemp_tlt'>{salary_calculation === "ctc" ? "Monthly CTC" : "Gross Salary Per Month"} </p>
              <Form.Item
                name="monthly_ctc"
                rules={[{ required: true, message: "This field is required" }]}
              >
                {renderAmountInput("monthly_ctc", "", "", true)}
              </Form.Item>
              <p className='salaryTemp_tlt'>{salary_calculation === "ctc" ? "Annual CTC" : "Gross Salary Per Annum"} </p>
              <Form.Item
                name="annual_ctc"
                rules={[{ required: true, message: "This field is required" }]}
              >
                {renderAmountInput("annual_ctc", "", "", true)}
              </Form.Item>
            </div>

            {/* Earning Components start */}
            <div className='w-full flex justify-between items-center py-2 border-b'>
              <p className='salaryTemp_tlt'>Earning Components</p>
              {formData?.earning_components?.length > 0 ?
                <Form.Item noStyle>
                  <IconButton
                    title="Edit"
                    icon={<img src={ImagePaths.edit.default} alt="Edit" />}
                    disabled={formData?.is_edit}
                    onClick={() => (handleComponentsEdit("earning", formData?.earning_components))}
                  />
                </Form.Item>
                : null}
            </div>
            {renderTlt()}
            {
              formData?.earning_components?.length > 0 ?
                formData?.earning_components?.map((item, index) => {
                  return (
                    <div className='w-full grid grid-cols-4 gap-x-10 py-3' key = {index}>
                      <Form.Item
                        style={{ textAlign: "left" }}
                        name={["earning_components", index, "name"]}
                      >
                        <p className='salaryTemp_txt'>{`${item?.name} ${item?.name == "HRA" ? "(From Basic)" : "(From Gross)"}`}</p>
                      </Form.Item>
                      <Form.Item
                        name={["earning_components", index, "percentage"]}
                        rules={[{ required: true, message: "This field is required" }]} 
                      >
                        {renderPercentInput("earning_components", "percentage", index, false, item?.name)}
                        <p className='salaryTemp_txt'>{`${(item?.name === "Basic" || item?.name === "HRA") ? "(40 - 50)" : "" } `}</p>
                      </Form.Item>
                      <Form.Item
                        name={["earning_components", index, "monthly_amount"]}
                        rules={[{ required: true, message: "This field is required" }]}
                      >
                        {renderAmountInput("earning_components", "monthly_amount", index, true)}
                      </Form.Item>
                      <Form.Item
                        name={["earning_components", index, "annual_amount"]}
                        rules={[{ required: true, message: "This field is required" }]}
                      >
                        {renderAmountInput("earning_components", "annual_amount", index, true)}
                      </Form.Item>
                    </div>
                  )
                })
                : <div
                  className="w-full h-2/5 flex justify-center items-center subEmployee_details_content border-b">
                  No Data Found
                </div>
            }
            <div className="w-full grid grid-cols-4 gap-x-10 py-3 border-b">
              {salary_calculation === "ctc" ?
                <>
                  <p className='salaryTemp_tlt'>Gross Salary</p>
                  <p className='salaryTemp_tlt'>
                    {/* {`${formData?.total_earning?.percentage || 0} %`} */}
                  </p>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.total_earning?.monthly_amount || 0}`}</p>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.total_earning?.annual_amount || 0}`}</p>
                </> :
                <>
                  <p className='salaryTemp_tlt'>Monthly CTC</p>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.monthly_ctc || 0}`}</p>
                  <p className='salaryTemp_tlt'>Annual CTC</p>
                  <p className='salaryTemp_tlt'>{`₹ ${formData?.annual_ctc || 0}`}</p>
                </>
              }
            </div>
            {/* Earning Components End */}
            {/* Deduction Components start */}
            <div className='w-full flex justify-between items-center py-2 border-b'>
              <p className='salaryTemp_tlt'>Deduction Components</p>
              {formData?.deduction_components?.length > 0 ?
                <Form.Item noStyle>
                  <IconButton
                    title="Edit"
                    icon={<img src={ImagePaths.edit.default} alt="Edit" />}
                    disabled={formData?.is_edit}
                    onClick={() => (handleComponentsEdit("deduction", formData?.deduction_components))}
                  />
                </Form.Item>
                : null}
            </div>
            {renderTlt()}
            {formData?.deduction_components?.length > 0 ?
              <div className="grid grid-cols-4 gap-x-10">
                <div></div>
                <div className="grid grid-cols-2 gap-x-10">
                  <p className="pt-3 salaryTemp_txt">Employee Share</p>
                  <p className="pt-3 salaryTemp_txt">Employer Share</p>
                </div>
                <div className="grid grid-cols-2 gap-x-10">
                  <p className="pt-3 salaryTemp_txt">Employee Share</p>
                  <p className="pt-3 salaryTemp_txt">Employer Share</p>
                </div>
              </div> : null}
            {
              formData?.deduction_components?.length > 0 ?
                formData?.deduction_components?.map((item, index) => {
                  return (
                    <div className="grid grid-cols-4 gap-x-10 py-3" key = {index}>
                      <Form.Item
                        style={{ textAlign: "left" }}
                        name={["deduction_components", index, "name"]}
                      >
                        <p className='salaryTemp_txt'>{`${item?.name} ${item?.name == "PF" ? "(From Earnings Except HRA)" : "(From Gross)"}`}</p>
                      </Form.Item>
                      <div className='grid grid-cols-2 gap-x-10'>
                        {item?.is_employee_share && !item?.is_amount? <Form.Item
                          name={["deduction_components", index, "employee_share_percentage"]}
                          rules={[{ required: true, message: "This field is required" }]}
                        >
                          {renderPercentInput("deduction_components", "employee_share_percentage", index, false)}
                          <p className='salaryTemp_txt'>{`${item?.name === "PF" ? "(below 12)" : ""}`}</p>
                        </Form.Item> : <Form.Item />}
                        {item?.is_employer_share && !item?.is_amount? <Form.Item
                          name={["deduction_components", index, "employer_share_percentage"]}
                          rules={[{ required: true, message: "This field is required" }]}
                        >
                          {renderPercentInput("deduction_components", "employer_share_percentage", index, false)}
                          <p className='salaryTemp_txt'>{`${item?.name === "PF" ? "(below 12)" : ""}`}</p>
                        </Form.Item> : <Form.Item />}
                      </div>
                      <div className='grid grid-cols-2 gap-x-10'>
                        {item?.is_employee_share ?
                          <Form.Item
                            name={["deduction_components", index, "employee_share_monthly_amount"]}
                            rules={[{ required: true, message: "This field is required" }]}
                          >
                            {renderAmountInput("deduction_components", "employee_share_monthly_amount", index, !item?.is_amount)}
                          </Form.Item> : <Form.Item />}
                        {item?.is_employer_share ? <Form.Item
                          name={["deduction_components", index, "employer_share_monthly_amount"]}
                          rules={[{ required: true, message: "This field is required" }]}
                        >
                          {renderAmountInput("deduction_components", "employer_share_monthly_amount", index, !item?.is_amount)}
                        </Form.Item> : <Form.Item />}
                      </div>
                      <Form.Item
                        name={["deduction_components", index, "annual_amount"]}
                        rules={[{ required: true, message: "This field is required" }]}
                      >
                        {renderAmountInput("deduction_components", "annual_amount", index, true)}
                      </Form.Item>
                    </div>
                  )
                })
                : <div
                  className="w-full h-2/5 flex justify-center items-center subEmployee_details_content border-b">
                  No Data Found
                </div>
            }
            <div className="w-full grid grid-cols-4 gap-x-10 py-3 border-b">
              <p className='salaryTemp_tlt'>Total Deduction</p>
              <div className='w-full grid grid-cols-2 gap-x-5'>
                <p className='salaryTemp_tlt'>
                  {/* {`${formData?.total_deduction?.percentage || 0} %`} */}
                </p>
              </div>
              <div className='w-full grid grid-cols-2 gap-x-5'>
                <p className='salaryTemp_tlt'>{`₹ ${formData?.total_deduction?.employee_monthly_amount || 0}`}</p>
                <p className='salaryTemp_tlt'>{`₹ ${formData?.total_deduction?.employer_monthly_amount || 0}`}</p>
              </div>
              <p className='salaryTemp_tlt'>{`₹ ${formData?.total_deduction?.annual_amount || 0}`}</p>
            </div>
            <div className='w-full grid grid-cols-4 gap-x-10 py-3 border-b'>
              <p className='salaryTemp_tlt'>Net Salary</p>
              <p className='salaryTemp_tlt'></p>
              <p className='salaryTemp_tlt'>{`₹ ${formData?.net_salary || 0}`}</p>
              <p className='salaryTemp_tlt'>{`₹ ${formData?.net_salary || 0}`}</p>
            </div>
            {/* Deduction Components End */}
          </div>
        </div>
        <div className="w-full flex justify-end items-end" style={{ height: "5dvh" }}>
          <Form.Item >
            <div className='flex justify-end items-end mr-2'>
              <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true)} : handleBackCancel} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
              {fromEdit ? null
                : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loadings} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />}
              <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loadings} paddingX={"0 1.2vw"} />
            </div>
          </Form.Item>
        </div>
      </Form>
      {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} testID='hs' /> : null}
      {/* {isalert ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={handleSaveInactive} testID='hs' sentence="Inactivating this Salary Structure will impact the Salary Template and Salary Structure. Please ensure this change is intentional, as it may affect payroll calculations" okBtnLable="Update"  width={"30vw"}/> : null} */}
    </Card>
  )
}

const styles = {
  ctcStyle: {
    width: "100%",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderRadius: "0px",
    // backgroundColor: "#F5F8F7",
  }
}

export default AddEditSalaryTemplate