import { monthEnum } from "components/common/enum";
import MyButton from "components/ui/Button/MyButton";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Input, Card, Checkbox, Select, Radio } from 'antd';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";
import IconButton from "components/ui/Button/IconButton";
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys, validateRangeMandatory } from 'components/common/validation';
import { PAYROLL_PERIOD } from "constants";

const AddEditPayrollPeriod = () => {
    const authtoken = sessionStorage.getItem("token");
    const companyid = sessionStorage.getItem("userId");
    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const [formData, setFormData] = useState({
        code: "",
        period: "customize",
        payroll_period_name: "",
        custom_month: [],
        custom_start_day: 0,
        custom_end_day: 0,
        month: [0],
        start_day: 1,
        end_day: 31,
        is_active: true,
        notes: "",
        company_id: companyid
    });
    const [loading, setLoading] = useState(false);
    const [isCancelClk, setIsCancelClk] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [omittedValues, setOmittedValues] = useState([]);
    const prevPropsRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    const { fromEdit, editDetails, isActive } = location.state || {};
    const [form] = Form.useForm();
    const [isSelectorOpen, setSelectOpen] = useState(false);

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
            let updateData = {
                code: editDetails?.code ? editDetails?.code : "",
                period: editDetails?.period ? editDetails?.period : "customize",
                payroll_period_name: editDetails?.payroll_period_name ? editDetails?.payroll_period_name : "",
                custom_month: editDetails?.period === "customize" ? editDetails?.month : [],
                custom_start_day: editDetails?.period === "customize" ? editDetails?.start_day : 0,
                custom_end_day: editDetails?.period === "customize" ? editDetails?.end_day : 0,
                month: editDetails?.period === "month_wise" ? editDetails?.month : [0],
                start_day: editDetails?.period === "month_wise" ? editDetails?.start_day : 1,
                end_day: editDetails?.period === "month_wise" ? editDetails?.end_day : 31,
                notes: editDetails?.notes ? editDetails?.notes : "",
                is_active: editDetails?.is_active,
                company_id: editDetails?.company_id
            }
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.id,
                ...updateData
            }));
            form.setFieldsValue({...updateData});
            prevPropsRef.current = editDetails;
        }
    }, [editDetails, form, formData])

    const handleRadioChange = (e) => {
        const { value } = e.target;
        let updatedFormData = { ...formData };
        if (value === "month_wise") {
            updatedFormData = {
                ...updatedFormData,
                period: "month_wise",
                // month: [0],
                // start_day: 1,
                // end_day: 31,
            };
        } else if (value === "customize") {
            updatedFormData = {
                ...updatedFormData,
                period: "customize",
                // custom_month: [],
                // custom_start_day: 0,
                // custom_end_day: 0,
            };
        }
        setFormData(updatedFormData);
        form.setFieldsValue(updatedFormData);
    }

    const handleChange = (name, value) => {
        const updatedFormData = { ...formData, [name]: value };
        switch (name) {
            case "custom_start_day":
                updatedFormData[name] = parseInt(value || 0);
                if (!value) {
                    updatedFormData.custom_end_day = 0;
                }
                let endDay = parseInt(updatedFormData.custom_start_day || 0) - 1;
                updatedFormData["custom_end_day"] = endDay === -1 ? 0 : (endDay === 0 ? 31 : endDay);
                break;
            case "custom_month":
            case "month":
                updatedFormData[name] = value?.includes(0) ? [0] : value;
                setSelectOpen((name === "month" || name === "custom_month") && value.includes(0) ? false : true);
                break;
        }
        setFormData(updatedFormData);
        form.setFieldsValue(updatedFormData);
    };

    const handleKeyDown = (e) => {
        // Allow only numbers, backspace, and delete keys
        if (!/^\d$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
            e.preventDefault();
        }
        const newValue = e.target.value === "" ? e.key : e.target.value + e.key;
        if ((e.key !== "Backspace" && e.key !== "Delete") && (parseInt(newValue, 10) < 1 || parseInt(newValue, 10) > 31)) {
            e.preventDefault();
        }
    };

    const renderInput = (label, statekey, testID = '', maxLength = 150, isReadonly = false, required = false) => {
        const dayFields = {
            start_day: true,
            end_day: true,
            custom_start_day: true,
            custom_end_day: true,
        };
        // Determine if statekey is a day field
        const isDayField = dayFields[statekey];
        const inputStyle = isDayField ? styles.dayField : styles.textField;
        const handleInputKeyDown = isDayField ? handleKeyDown : null;

        return (
            <>
                <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
            <Input
                autoComplete='off'
                style={inputStyle}
                type={"text"}
                name={statekey}
                value={formData[statekey]}
                maxLength={maxLength}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                disabled={isReadonly}
                onKeyDown={handleInputKeyDown}
                data-testID={testID}
            />
            </>
        )
    }

    const handleToggleTooltip = (visible, values, position) => {
        setShowTooltip(visible);
        setOmittedValues(values);
        setTooltipPosition(position);
      };
    
      const handleMouseEnter = (event, omittedValues) => {
        const rect = event.target.getBoundingClientRect();
        handleToggleTooltip(true, omittedValues, { x: rect.left, y: rect.bottom });
      };
    
      const handleMouseLeave = () => {
        handleToggleTooltip(false, [], { x: 0, y: 0 });
      };
    
      const renderMaxTagPlaceholder = (omittedValues) => (
        <div
          onMouseEnter={(event) => handleMouseEnter(event, omittedValues)}
          onMouseLeave={handleMouseLeave}
          style={{ cursor: 'pointer' }}
        >
          +{omittedValues.length}
        </div>
      );

    const renderSelect = (label, statekey, testID = '', list, showKey = "text", valueKey = "value", isDisabled = false, required = false) => {
        return (
            <>
                <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
            <Select
                showSearch
                mode="multiple"
                showArrow="true"
                maxTagCount={2}
                maxTagTextLength={6}
                style={styles.textField}
                value={formData[statekey] ? formData[statekey] : undefined}
                name={statekey}
                onChange={(value) => handleChange(statekey, value)}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={isDisabled}
                data-testid={testID}
                open={isSelectorOpen}
                onDropdownVisibleChange={(isOpen) => setSelectOpen(isOpen)}
                maxTagPlaceholder={(omittedValues) => renderMaxTagPlaceholder(omittedValues)}
            >
                {list.map((option) => (
                    <Select.Option key={option[valueKey]} value={option[valueKey]}>{option[showKey]}</Select.Option>
                ))}
            </Select>
                {omittedValues.length > 0 && showTooltip && (
                    <div className="commonTooltipOfMutliSlt"
                        style={{ position: 'fixed', top: tooltipPosition.y + 10, left: tooltipPosition.x }}>
                        <ul className="commonTooltipOfMutliSltDiv">
                            {omittedValues.map(value => (
                                <li key={value.key} className="commonTooltipOfMutliSltTxt" style={{ width: "8dvw" }}>{value.label}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </>
        )
    }

    const handleBackCancel = useCallback(() => {
        form.resetFields();
        navigate('/configuration/payrollperiod');
    }, [navigate, form])

    const handleCancelBtn = () => {
        setIsCancelClk(false);
        handleBackCancel();
    }

    const handleSubmit = useCallback((isDraftClked) => {
        let methods = fromEdit ? "PUT" : "POST";
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoading(true)
            let updatedFormData = { ...formData };
            updatedFormData = {
                ...updatedFormData,
                month: updatedFormData?.period === "month_wise" ? updatedFormData.month : updatedFormData.custom_month,
                start_day: updatedFormData?.period === "month_wise" ? updatedFormData?.start_day ? +updatedFormData?.start_day : 1
                    : updatedFormData?.custom_start_day ? +updatedFormData?.custom_start_day : 0,
                end_day: updatedFormData?.period === "month_wise" ? updatedFormData?.end_day ? +updatedFormData.end_day : 31
                    : updatedFormData?.custom_end_day ? +updatedFormData?.custom_end_day : 0,
                is_draft: isDraftClked ? true : false
            };
            delete updatedFormData.custom_month;
            delete updatedFormData.custom_start_day;
            delete updatedFormData.custom_end_day;
            fetch(PAYROLL_PERIOD, {
                method: methods,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleBackCancel();
                        setLoading(false);
                        setFormData({
                            code: "",
                            period: "customize",
                            payroll_period_name: "",
                            custom_month: [],
                            custom_start_day: 0,
                            custom_end_day: 0,
                            month: [0],
                            start_day: 1,
                            end_day: 31,
                            is_active: true,
                            notes: "",
                            company_id: companyid
                        })
                    }
                    else if (data.status === "fail") {
                        setLoading(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        } finally {
            setIsCancelClk(false);
        }
    }, [formData, fromEdit, companyid, handleBackCancel]);

    const checkAllMandatory = useCallback((isDraftClked) => {
        if (!isDraftClked) {
          form
            .validateFields()
            .then(() => {
              handleSubmit(false);
            })
            .catch(error => {
              console.error("Validation Error:", error);
            });
        } else {
          handleSubmit(true);
        }
      }, [form, handleSubmit]);

    const handleSave = useCallback(() => {
        checkAllMandatory(false);
    }, [checkAllMandatory]);

    const handleSaveAsDraft = useCallback(() => {
        checkAllMandatory(true);
    }, [checkAllMandatory]);

    const validateStartEndDay = (rule, value) => {
        return new Promise((resolve, reject) => {
            const startDay = form.getFieldValue('start_day');
            if (startDay !== 0 && value !== 0 && value === startDay) {
                reject('End day cannot be same as start day');
            } else {
                resolve();
            }
        });
    };

    const keysToExclude = ['is_active', 'company_id', 'period', 'month', 'start_day', 'end_day'];
    const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

    return (
        <Card className='h-full'>
            <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
                <div className='flex'>
                    <IconButton title="Back" icon={<img src={ImagePaths.backArrow.default} alt="Back" />} onClick={handleBackCancel} testID="pp_back" />
                    <p className='common_addTltTxt'>{fromEdit ? "Edit Payroll Period" : "Add Payroll Period"}</p>
                </div>
                <Checkbox
                    disabled = {fromEdit ? !isActive : true }
                    checked={formData.is_active}
                    onChange={(e) => handleChange("is_active", e.target.checked)}
                    data-testid={'pp_sts'}
                >
                    Active
                </Checkbox>
            </div>
            <div className='h-5/6' style={{ margin: "16px 0px"}}>
                <Form
                    layout="inline"
                    form={form}
                    colon={false}
                    requiredMark={false}
                    className='w-full'
                    name="basicform"
                    onFinish={handleSave}
                    onSubmit={(e) => e.preventDefault()}
                    initialValues={formData}
                    data-testid={'pp_form'}
                >
                    <div className='w-full m-1'>
                        <Form.Item name="period" style={styles.radioItem}>
                            <Radio.Group value={formData.period} onChange={handleRadioChange} data-testid={'pp_period'}>
                                <Radio value="month_wise">Month Wise</Radio>
                                <Radio value="customize">Customize</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <p id="common_weekoffTxt" className="w-full ml-6">Payroll Period Details</p>
                    <div className='w-full grid grid-rows-2 grid-cols-3 gap-5 m-1'>
                        {fromEdit ? <Form.Item
                            style={styles.formItem}
                            name="code"
                            rules={[{ required: true, message: "This field is required" },
                            { validator: noBlankSpacesValidator }
                            ]}
                        >
                            {renderInput('Payroll Period Code', "code", 'pp_code', 10, true, true)}
                        </Form.Item> : null}
                        <Form.Item
                            style={styles.formItem}
                            name="payroll_period_name"
                            rules={[{ required: true, message: "This field is required" },
                            { validator: noBlankSpacesValidator }
                            ]}
                        >
                            {renderInput('Payroll Period Name', "payroll_period_name", 'pp_name', 50, false, true)}
                        </Form.Item>

                        <Form.Item
                            style={styles.formItem}
                            name={formData.period === "month_wise" ? "month" : "custom_month"}
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            {renderSelect('Select Month', formData.period === "month_wise" ? "month" : "custom_month", 'pp_month', monthEnum, "text", "value", formData.period === "month_wise" && formData.month.includes(0) && monthEnum.values !== 0, formData.period === "month_wise" ? false : true)}
                        </Form.Item>
                        <div className='w-full grid grid-cols-2 gap-5'>
                        <Form.Item
                            style={styles.formItem}
                            name={formData.period === "month_wise" ? "start_day" : "custom_start_day"}
                            rules={[{ required: true, message: "This field is required" },
                             { validator: validateRangeMandatory(1, 31)}
                            ]}
                        >
                            {renderInput('Start Day', formData.period === "month_wise" ? "start_day" : "custom_start_day", 'pp_start', 2, formData.period === "month_wise", formData.period === "month_wise" ? false : true)}
                        </Form.Item>
                        <Form.Item
                            style={styles.formItem}
                            name={formData.period === "month_wise" ? "end_day" : "custom_end_day"}
                            rules={[
                            { validator: validateRangeMandatory(1, 31)},
                            { validator: validateStartEndDay }
                            ]}
                            tooltip={{
                                title: "This field is required ",
                            }}
                        >
                            {renderInput('End Day', formData.period === "month_wise" ? "end_day" : "custom_end_day", 'pp_end', 2, true, false)}
                        </Form.Item>
                        </div>
                        <Form.Item name="notes" style={styles.formItem}>
                            {renderInput('Notes', "notes", 'pp_notes', 150)}
                        </Form.Item>
                        <Form.Item style={styles.emptyFormItem} />
                    </div>
                    <div className='w-full relative inset-y-20'>
                        <Form.Item >
                            <div className='flex justify-end items-end mr-2'>
                                <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="pp_cancel" />
                                {fromEdit ? null
                                    : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="pp_draft" />}
                                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loading} paddingX={"0 1.2vw"} testID="pp_save" />
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </div>
            {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} testID="pp" /> : null}
        </Card>
    );
};

const styles = {
    textField: {
        width: "23vw"
    },
    formItem: {
        margin: "1vw"
    },
    emptyFormItem: {
        width: "23vw",
        margin: "1vw"
    },
    dayField: {
        width: "10.5vw"
    },
    radioItem: {
        margin: "0.625vw"
    }
}
export default AddEditPayrollPeriod
